import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { AppVersion } from "@farmact/model/src/model/AppMeta";
import { isPlatform } from "@ionic/react";
import { useEffect, useState } from "react";
import { appVersion } from "@/config/config";
import { appStoreLinks } from "@/constants/links";
import { computed } from "@/util/functions";
import { openLink } from "@/util/openLink";
import { compareVersions, getCurrentAppVersion } from "@/util/version";
import { useAppMeta } from "./useAppMeta";

type UseWatchNewVersionReturn = {
    update: () => void;
    dismiss: () => void;
    dismissed: boolean;
    outdated: boolean;
};

export function useWatchNewVersion(): UseWatchNewVersionReturn {
    const [appMeta] = useAppMeta();

    const [dismissed, setDismissed] = useState(true);

    // used to force rerender
    const [, setRender] = useState(false);
    const version = appMeta ? getCurrentAppVersion(appMeta) : undefined;

    const outdated = computed((): boolean => {
        if (!version) {
            return false;
        }

        return compareVersions(appVersion, version) < 0;
    });

    useEffect(() => {
        const loadDismissed = async () => {
            if (!version) {
                return;
            }

            const key = createLSKey(version);
            const { value } = await Preferences.get({ key });
            setDismissed(value === "true");
        };
        loadDismissed();
    }, [version]);

    const update = () => {
        dismiss();

        if (Capacitor.isNativePlatform()) {
            if (isPlatform("ios")) {
                return openLink(appStoreLinks.ios);
            } else if (isPlatform("android")) {
                return openLink(appStoreLinks.android);
            }

            return;
        }

        return location.reload();
    };

    const dismiss = () => {
        if (!version) {
            return;
        }

        Preferences.set({ key: createLSKey(version), value: "true" });
        // we "force" a rerender so `outdated` is reevaluated
        setRender(prev => !prev);
    };

    return {
        update,
        dismiss,
        //
        dismissed,
        outdated,
    };
}

function createLSKey(version: AppVersion) {
    const versionText = `${version.major}.${version.minor}.${version.patch}`;
    return `farmact.version.${versionText}.dismissed`;
}
