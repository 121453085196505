import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonImg,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";

export const SignUpPage = (props: RouteComponentProps) => {
    const query = new URLSearchParams(props.location.search);

    const hasQueryParams = query.has("token") && query.has("dt") && query.has("mail") && query.has("company");

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Willkommen bei FarmAct!</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonImg src="assets/farmact_banner.png" style={{ width: "20rem", margin: "auto" }} />
                {hasQueryParams ? (
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" sizeLg="6" offsetLg="3">
                                <SignUpForm
                                    token={query.get("token")!}
                                    email={query.get("mail")!}
                                    company={query.get("company")!}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                ) : (
                    <p className="ion-text-center">
                        <IonText color="danger">Registrierung nicht möglich</IonText>
                    </p>
                )}
            </IonContent>
        </IonPage>
    );
};
