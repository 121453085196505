export function roundToClosestMultiple(of: number, value: number): number {
    if (of === 0) {
        return 0;
    }

    const up = Math.ceil(value / of) * of;
    const down = Math.floor(value / of) * of;

    const upDiffToValue = up - value;
    const downDiffToValue = value - down;

    return upDiffToValue < downDiffToValue ? up : down;
}

export function numberRange(from: number, to: number): number[] {
    const values: number[] = [];

    for (let i = from; i <= to; i++) {
        values.push(i);
    }

    return values;
}

export function clamp(min: number, max: number, value: number): number {
    return Math.min(Math.max(min, value), max);
}

export function randomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(min + Math.random() * (max - min));
}

export function getOrderOfMagnitude(value: number) {
    const betweenZeroAndOne = Math.abs(value) > 0 && Math.abs(value) < 1;
    let valueToCheck = betweenZeroAndOne ? 1 / value : value;

    let orderOfMagnitude = betweenZeroAndOne ? 1 : 0;
    while (Math.abs(valueToCheck) >= 10) {
        orderOfMagnitude++;
        valueToCheck /= 10;
    }
    return orderOfMagnitude * (betweenZeroAndOne ? -1 : 1);
}
