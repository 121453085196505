import { Activity } from "@farmact/model/src/model/Employee";
import { useUserContext } from "@/components/authentication/Session/useUserContext";

export type UseCurrentActivityReturn = {
    currentActivity: Activity | null;
    isLoading: boolean;
};

export function useCurrentActivity(): UseCurrentActivityReturn {
    const { employee, isLoading } = useUserContext();

    const currentActivity = employee?.currentActivity ?? null;

    return { currentActivity, isLoading };
}
