"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankConnectionService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class BankConnectionService {
    /**
     * 🔗 Get all bank connections
     * @returns GetBankConnectionsResponseDto
     * @throws ApiError
     */
    static getBankConnections({ companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/connections',
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 🆕 Initiate a new bank connection
     * @returns CreateBankConnectionResponseDto
     * @throws ApiError
     */
    static createBankConnection({ institutionId, companyId, operatingUnitId, redirect, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/connections',
            query: {
                'institutionId': institutionId,
                'redirect': redirect,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 🔗 Get a specific bank connection by id
     * @returns GetBankConnectionResponseDto
     * @throws ApiError
     */
    static getBankConnection({ id, companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/connections/{id}',
            path: {
                'id': id,
            },
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 🆕 Renew an existing bank connection
     * @returns RenewBankConnectionResponseDto
     * @throws ApiError
     */
    static renewBankConnection({ id, companyId, operatingUnitId, redirect, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/connections/{id}',
            path: {
                'id': id,
            },
            query: {
                'redirect': redirect,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 🗑️ Remove a bank connection
     * By default (<code>force=false</code>) removing a bank connection only terminates all connections between the bank and gocardless. Transactions which are already pulled by now won't be deleted. If you wish to permanently remove all records do so by setting <code>force</code> to <code>true</code>.
     * @returns DeleteBankConnectionResponseDto
     * @throws ApiError
     */
    static deleteBankConnection({ id, companyId, operatingUnitId, force, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/connections/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.BankConnectionService = BankConnectionService;
