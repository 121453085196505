import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const GlobalSettingsPageContent = lazy(() => import("./GlobalSettingsPageContent"));

function GlobalSettingsPageInternal() {
    return (
        <DefaultPage>
            <GlobalSettingsPageContent />
        </DefaultPage>
    );
}

export const GlobalSettingsPage = withAuthorization(getAuthorizationGuard(Role.ADMIN, Role.OWNER))(
    GlobalSettingsPageInternal
);
