import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const StripeErrorPageContent = lazy(() => import("./StripeErrorPageContent"));

function StripeErrorPageInternal() {
    return (
        <DefaultPage>
            <StripeErrorPageContent />
        </DefaultPage>
    );
}

export const StripeErrorPage = withAuthorization(getAuthorizationGuard(Role.OWNER))(StripeErrorPageInternal);
