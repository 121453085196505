import { FaAlert } from "@/components/FaAlert/FaAlert";

type LogoutAlertProps = {
    show: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

export function LogoutAlert(props: LogoutAlertProps) {
    return (
        <FaAlert
            isOpen={props.show}
            onDismiss={() => props.onCancel()}
            title="Abmelden"
            message="Willst du dich wirklich abmelden?"
            actions={[
                {
                    text: "Nein",
                    color: "dark",
                },
                {
                    text: "Ja",
                    handler: () => props.onConfirm(),
                    color: "danger",
                },
            ]}
        />
    );
}
