import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { Resource } from "@farmact/model/src/model/Resource";
import { ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { SelectVariantModal } from "@/components/inputs/resourceInputs/SelectVariantModal/SelectVariantModal";
import { useResourceSelection } from "@/components/inputs/resourceInputs/utils/useResourceSelection";
import { useResourceSelectItems } from "@/components/inputs/resourceInputs/utils/useResourceSelectItems";
import { constructResourceVariantIdentifier } from "@/components/inputs/resourceInputs/utils/utils";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { getEmployeeHasAccess } from "@/util/accessUtils";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";

type SingleResourceInputProps = {
    selectedResource: ResourceVariantIdentifier | null;
    availableResources: Resource[];
    availableLiquidMixtures?: LiquidMixture[];
    onChange: (updatedResource: ResourceVariantIdentifier | null) => void;
    error?: SingleValidationError;
    allowSelectionOfBaseVariant?: boolean;
    allowCreatingLiquidMixture?: boolean;
    clearable?: boolean;
    placeholder?: string;

    className?: string;
};

export const SingleResourceInput = (props: SingleResourceInputProps) => {
    const { employee: loggedInEmployee } = useUserContext();
    const {
        modal: selectVariantModal,
        dismissModal: dismissSelectVariantModal,
        onChange: handleSelectionChange,
    } = useResourceSelection({
        availableResources: props.availableResources,
        availableLiquidMixtures: props.availableLiquidMixtures ?? [],
        onResourceSelect: props.onChange,
    });

    const resourceOptionsToDisplay = useResourceSelectItems({
        availableResources: props.availableResources.filter(resource => {
            if (props.selectedResource) {
                if (resource.id === props.selectedResource.resourceId) {
                    return true;
                }
            }

            return getEmployeeHasAccess(loggedInEmployee, resource.operatingUnitIds);
        }),
        selectedResource: props.selectedResource,
        allowCreatingLiquidMixture: props.allowCreatingLiquidMixture,
        availableLiquidMixtures: props.availableLiquidMixtures,
    });

    const currentSelectedOption = props.selectedResource
        ? constructResourceVariantIdentifier(props.selectedResource.resourceId, props.selectedResource.variantId)
        : undefined;

    return (
        <>
            <SingleSelect
                data={resourceOptionsToDisplay}
                value={currentSelectedOption}
                onChange={value => handleSelectionChange(value ?? undefined)}
                error={props.error}
                clearable={props.clearable}
                placeholder={props.placeholder ?? "Artikel"}
                searchPlaceholder={`${props.placeholder ?? "Artikel"} durchsuchen`}
                className={props.className}
                dropdownOpen={selectVariantModal.show ? false : undefined}
            />
            <SelectVariantModal
                isOpen={selectVariantModal.show}
                onDismiss={dismissSelectVariantModal}
                resource={selectVariantModal.resource}
                onVariantSelect={props.onChange}
                allowSelectionOfBaseVariant={props.allowSelectionOfBaseVariant}
            />
        </>
    );
};
