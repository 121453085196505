import { OpenAPI as OcrApi } from "@farmact/ocr-service-client";
import { environment } from "@/config/config";

const localOcrApiPath = "/localApi/ocr";

export function setupOcrApi() {
    if (environment == "local") {
        OcrApi.BASE = localOcrApiPath;
    }
    if (environment === "dev" || environment === "alpha") {
        OcrApi.BASE = "https://dev.api.ocr.farmact.de";
    }
    if (environment === "beta") {
        OcrApi.BASE = "https://beta.api.ocr.farmact.de";
    }
    if (environment === "prod") {
        OcrApi.BASE = "https://api.ocr.farmact.de";
    }
}
