import { Capacitor } from "@capacitor/core";
import { AppMeta, AppVersion } from "@farmact/model/src/model/AppMeta";
import { isPlatform } from "@ionic/react";

/**
 * @param version1
 * @param version2
 * @returns zero if both versions are the same, a negative number if `version1` is below `version2` and a positive number otherwise
 */
export function compareVersions(version1: AppVersion, version2: AppVersion) {
    if (version1.major !== version2.major) {
        return version1.major - version2.major;
    }

    if (version1.minor !== version2.minor) {
        return version1.minor - version2.minor;
    }

    return version1.patch - version2.patch;
}

/**
 * Get the current app version for the active platform
 * @param appMeta
 */
export function getCurrentAppVersion(appMeta: AppMeta): AppVersion {
    if (Capacitor.isNativePlatform()) {
        if (isPlatform("ios")) {
            return appMeta.currentVersion.ios;
        } else if (isPlatform("android")) {
            return appMeta.currentVersion.android;
        }
    }

    return appMeta.currentVersion.web;
}
