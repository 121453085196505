import { lazy } from "react";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CustomMailActionPageContent = lazy(() => import("./CustomMailActionPageContent"));

export function CustomMailActionPage() {
    return (
        <DefaultPage>
            <CustomMailActionPageContent />
        </DefaultPage>
    );
}
