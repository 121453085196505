import { IonButton, IonText } from "@ionic/react";
import { FormEvent, useState } from "react";
import { getMessageForAuthError } from "@/components/authentication/SignIn/util/signinUtils";
import * as Modal from "@/components/FaModal";
import { Input } from "@/components/inputs/Input/Input";
import { SubmitFormOnEnter } from "@/components/inputs/SubmitFormOnEnter";
import { Firebase } from "@/firebase";
import "./passwordForgetModal.scss";

const PasswordForgetModal = (props: PasswordForgetModalProps) => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState<any>();
    const [linkSent, setLinkSent] = useState(false);

    const handleDidDismiss = () => {
        setEmail("");
        setLinkSent(false);
        props.onDidDismiss();
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        try {
            await Firebase.instance().doPasswordReset(email);
            setLinkSent(true);
        } catch (error: any) {
            setErrorMessage(getMessageForAuthError(error));
        }
    };

    return (
        <Modal.Root isOpen={props.showModal} onDismiss={handleDidDismiss} className="password-forget-modal">
            <Modal.Header>Passwort zurücksetzen</Modal.Header>
            <Modal.Content>
                {linkSent ? (
                    <div>
                        <h3 className="ion-padding ion-text-center">
                            Wir haben Dir eine Mail zum Zurücksetzen Deines Passworts an {email} geschickt!
                        </h3>
                        <p className="ion-padding ion-text-center">
                            Falls Du nichts erhalten hast, schau bitte im Spam-Ordner nach und vergewissere Dich, dass
                            Du die richtige Email-Adresse eingegeben hast.
                        </p>
                    </div>
                ) : (
                    <form className="ion-padding" onSubmit={handleSubmit}>
                        <SubmitFormOnEnter />
                        <h3 className="ion-padding ion-text-center">Du hast Dein Passwort vergessen?</h3>
                        <p className="ion-padding ion-text-center">
                            Kein Problem! Gib einfach Deine Email-Adresse unten ein und wir schicken Dir eine Mail zum
                            Zurücksetzen Deines Passworts.
                        </p>
                        <Input
                            className="password-forget-modal__email-input"
                            name="email"
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Email-Addresse"
                            required={true}
                            type="email"
                            value={email}
                        />
                        {errorMessage && (
                            <IonText className="ion-text-center" color="secondary">
                                {errorMessage}
                            </IonText>
                        )}
                    </form>
                )}
            </Modal.Content>
            <Modal.Footer>
                {linkSent ? (
                    <IonButton color="primary" onClick={() => handleDidDismiss()}>
                        Alles klar!
                    </IonButton>
                ) : (
                    <>
                        <IonButton color="dark" onClick={() => props.onDidDismiss()}>
                            Doch nicht
                        </IonButton>
                        <IonButton color="primary" onClick={handleSubmit}>
                            Passwort zurücksetzen
                        </IonButton>
                    </>
                )}
            </Modal.Footer>
        </Modal.Root>
    );
};

type PasswordForgetModalProps = {
    showModal: boolean;
    onDidDismiss: () => void;
};

export default PasswordForgetModal;
