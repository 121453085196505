"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstitutionService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class InstitutionService {
    /**
     * 🏦 Find supported banks
     * Find supported banks by country and an optional search string. Search is case-insensitive and matches BIC and bank name.
     * @returns GetInstitutionsResponseDto
     * @throws ApiError
     */
    static getInstitutions({ countryCode, search, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/institutions',
            query: {
                'countryCode': countryCode,
                'search': search,
            },
        });
    }
}
exports.InstitutionService = InstitutionService;
