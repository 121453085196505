import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { SubMenu } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenu/SubMenu";
import { SubMenuItem } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenuItem/SubMenuItem";
import "./receiptsMenu.scss";

type ReceiptsMenuProps = {
    isOpen?: boolean;
    isInSplitPane: boolean;
};

export const ReceiptsMenu = (props: ReceiptsMenuProps) => {
    const { companyMeta } = useAppCompanyContext();

    return (
        <SubMenu isOpen={props.isOpen} cssClass="receipts-menu">
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Rechnungen"} route={Routes.BILLS} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Lieferscheine"} route={Routes.DELIVERY_NOTES} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Angebote"} route={Routes.OFFERS} />
            <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Gutschriften"} route={Routes.CREDIT_NOTES} />
            {companyMeta?.datevEnabled && (
                <SubMenuItem isInSplitPane={props.isInSplitPane} name={"Eingang"} route={Routes.INCOMING_BILLS} />
            )}
        </SubMenu>
    );
};
