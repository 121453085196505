import { User } from "firebase/auth";
import { useEffect } from "react";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";

export function useWatchEmployeeDeactivation(authUser: User | null | undefined) {
    const [userMeta] = useDocumentData(authUser?.uid ? Firebase.instance().getAppUserMeta(authUser.uid) : undefined, [
        authUser?.uid,
    ]);

    const tokenLastChanged = userMeta?.tokenLastChanged;
    useEffect(() => {
        (async () => {
            const claims = (await authUser?.getIdTokenResult())?.claims;

            if (!claims) {
                return;
            }

            const currentTokenIssueTime = Number(claims?.iat);
            const tokenHasChanged =
                currentTokenIssueTime && tokenLastChanged && currentTokenIssueTime <= tokenLastChanged;

            if (!tokenHasChanged) {
                return;
            }

            const defaultCompanyId = claims.defaultCompanyId as string | undefined;
            const inactiveCompanyIds: string[] = (claims.inactiveCompanyIds as string[]) ?? [];

            const userWasDeactivated = defaultCompanyId && inactiveCompanyIds.includes(defaultCompanyId);
            if (userWasDeactivated) {
                Firebase.instance()
                    .doSignOut()
                    .then(() => {
                        location.reload();
                    });
            }
        })();
    }, [authUser, tokenLastChanged]);
}
