import { PushNotificationSchema } from "@capacitor/push-notifications";
import { TASK_DETAILS } from "@farmact/model/src/constants/farmActAppRoutes";
import { AnyPushNotificationData, PushNotificationDataType } from "@farmact/model/src/model/PushNotificationData";
import { History } from "history";
import { Firebase } from "@/firebase";

export function handlePushNotification(notification: PushNotificationSchema, history: History) {
    const notificationData: AnyPushNotificationData = notification.data;
    if (notificationData.type === PushNotificationDataType.NEW_ORDER && notificationData.orderId) {
        Firebase.instance().updateNotification(notificationData.notificationId, { seen: true });
        history.push(TASK_DETAILS.replace(":orderId", notificationData.orderId));
    }
}
