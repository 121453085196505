"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenAPI = void 0;
exports.OpenAPI = {
    BASE: '',
    VERSION: '0.0.1-preview',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: { CLIENT_VERSION: '0.0.1-preview' },
    ENCODE_PATH: undefined,
};
