import cn from "clsx";
import { ReactNode } from "react";
import "./subMenu.scss";

type SubMenuProps = {
    isOpen?: boolean;
    cssClass?: string;
    children?: ReactNode;
};

export function SubMenu(props: SubMenuProps) {
    return <div className={cn(["sub-menu", props.isOpen && "sub-menu--open", props.cssClass])}>{props.children}</div>;
}
