import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./menuBadge.scss";

type MenuBadgeProps = ComponentPropsWithoutRef<"div"> & {
    badgeNumber: number;
};

export function MenuBadge(props: MenuBadgeProps) {
    const { className, badgeNumber, ...restProps } = props;
    return (
        <div className={clsx(className, "menu-badge")} {...restProps}>
            {badgeNumber}
        </div>
    );
}
