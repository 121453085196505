import { numberFormatterGenerator } from "@farmact/utils/src/formatters";
import * as Item from "@/components/FaItem";
import { NumberInput } from "@/components/inputs/NumberInput/NumberInput";
import { MachineCounterValueError } from "@/components/orders/Tasks/MachineCounters/MachineCounterQueries/MachineCounterValueError";
import { MachineCounterTrigger } from "@/components/orders/Tasks/MachineCounters/MachineCounterTrigger";
import { MachineCounterValue } from "@/components/orders/Tasks/MachineCounters/useMachineCounterGroups";
import { useLocaleFormatter } from "@/util/customHooks/useLocaleFormatter";
import { getMachineCounterTypeName } from "@/util/nameUtils/machineCounterTrackingNameUtils";

type MachineCounterInputItemProps = {
    onChange: (valueId: string, updatedValue: number | null) => void;
    value: MachineCounterValue;
    error?: MachineCounterValueError;
};

export const MachineCounterInputItem = (props: MachineCounterInputItemProps) => {
    const numberFormatter = useLocaleFormatter(numberFormatterGenerator(2, 0));

    let previousValue = undefined;
    if (props.value.previous) {
        previousValue =
            props.value.trigger === MachineCounterTrigger.START
                ? `Zuletzt: ${numberFormatter.format(props.value.previous)}`
                : `Vorher: ${numberFormatter.format(props.value.previous)}`;
    }

    return (
        <Item.Root error={props.error?.error}>
            <Item.Label>{getMachineCounterTypeName(props.value.type)}</Item.Label>
            <Item.Content>
                <NumberInput
                    step={0.1}
                    unit={previousValue}
                    currentValue={props.value.value}
                    onChange={updatedValue => props.onChange(props.value.id, updatedValue)}
                />
            </Item.Content>
        </Item.Root>
    );
};
