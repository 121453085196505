"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FMS`;
/* forward stack trace */
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FMS_400"] = "Bad Request";
    F_CODE["FMS_401"] = "Unauthorized";
    F_CODE["FMS_402"] = "Payment Required";
    F_CODE["FMS_403"] = "Forbidden";
    F_CODE["FMS_404"] = "Not Found";
    F_CODE["FMS_405"] = "Method Not Allowed";
    F_CODE["FMS_406"] = "Not Acceptable";
    F_CODE["FMS_407"] = "Proxy Authentication Required";
    F_CODE["FMS_408"] = "Request Timeout";
    F_CODE["FMS_409"] = "Conflict";
    F_CODE["FMS_410"] = "Gone";
    F_CODE["FMS_411"] = "Length Required";
    F_CODE["FMS_412"] = "Precondition Failed";
    F_CODE["FMS_413"] = "Payload Too Large";
    F_CODE["FMS_414"] = "URI Too Long";
    F_CODE["FMS_415"] = "Unsupported Media Type";
    F_CODE["FMS_416"] = "Range Not Satisfiable";
    F_CODE["FMS_417"] = "Expectation Failed";
    F_CODE["FMS_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FMS_421"] = "Misdirected Request";
    F_CODE["FMS_422"] = "Unprocessable Entity";
    F_CODE["FMS_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FMS_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FMS_425"] = "Too Early (RFC 8470)";
    F_CODE["FMS_426"] = "Upgrade Required";
    F_CODE["FMS_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FMS_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FMS_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FMS_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FMS_500"] = "Internal Server Error";
    F_CODE["FMS_501"] = "Not Implemented";
    F_CODE["FMS_502"] = "Bad Gateway";
    F_CODE["FMS_503"] = "Service Unavailable";
    F_CODE["FMS_504"] = "Gateway Timeout";
    F_CODE["FMS_505"] = "HTTP Version not supported";
    F_CODE["FMS_506"] = "Variant Also Negotiates";
    F_CODE["FMS_507"] = "Insufficient Storage";
    F_CODE["FMS_508"] = "Loop Detected";
    F_CODE["FMS_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FMS_510"] = "Not Extended";
    F_CODE["FMS_511"] = "Network Authentication Required";
    // =============
    // CUSTOM ERRORS
    // =============
    /*
     * Custom errors follow this convention: FDI_<HTTP_STATUS_CODE>_<AREA>_<SPECIFIC_ERROR_CODE>
     *
     * The <AREA> helps organizing the error codes into topics where the error originated
     *
     * AREAS:
     * 01: mail service
     * 02: mail template service
     * 03: firebase service
     * 04: sqs service
     *
     * The <SPECIFIC_ERROR_CODE> is a numeric code with 4 digits ranging from 0001 - 9999
     * giving detail about the specific error in the given <AREA>
     */
    // =======================
    // AREA: 01 - MAIL SERVICE
    // =======================
    F_CODE["FMS_400_01"] = "General mail service problem (bad request)";
    F_CODE["FMS_400_01_0001"] = "Mail bounce (undetermined)";
    F_CODE["FMS_400_01_0002"] = "Mail bounce (transient)";
    F_CODE["FMS_400_01_0003"] = "Mail bounce (permanent)";
    F_CODE["FMS_400_01_0004"] = "Mail reject";
    F_CODE["FMS_400_01_0005"] = "Mail complaint";
    // 5xx
    F_CODE["FMS_500_01"] = "General mail service problem (internal server error)";
    F_CODE["FMS_500_01_0001"] = "Cannot extract mail type";
    F_CODE["FMS_500_01_0002"] = "Cannot get mail config";
    F_CODE["FMS_500_01_0003"] = "Cannot compile template";
    F_CODE["FMS_500_01_0004"] = "Cannot assemble mail";
    F_CODE["FMS_500_01_0005"] = "Cannot send mail";
    F_CODE["FMS_501_01"] = "General mail service problem (not implemented)";
    F_CODE["FMS_501_01_0001"] = "SMTP not implemented";
    // ===========================
    // AREA: 03 - FIREBASE SERVICE
    // ===========================
    // 4xx
    F_CODE["FMS_400_03"] = "General firebase service problem (bad request)";
    // 5xx
    F_CODE["FMS_500_03"] = "General firebase service problem (internal server error)";
    // ======================
    // AREA: 04 - SQS SERVICE
    // ======================
    F_CODE["FMS_500_04"] = "General SQS service problem (internal server error)";
    F_CODE["FMS_500_04_0001"] = "Cannot parse SES event payload";
    F_CODE["FMS_500_04_0002"] = "Cannot extract property from SES event payload";
})(F_CODE || (exports.F_CODE = F_CODE = {}));
