import { AlertNotification } from "./AlertNotification";
import { Order } from "./Order";

export type BasePushNotificationData = {
    type: PushNotificationDataType;
};

export type NewOrderPushNotificationData = BasePushNotificationData & {
    type: PushNotificationDataType.NEW_ORDER;
    orderId: Order["id"];
    notificationId: AlertNotification["id"];
};

export enum PushNotificationDataType {
    NEW_ORDER = "NEW_ORDER",
}

export type AnyPushNotificationData = NewOrderPushNotificationData;
