import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const BillsPageContent = lazy(() => import("./BillsPageContent"));

function BillsPageInternal() {
    return (
        <DefaultPage>
            <BillsPageContent />
        </DefaultPage>
    );
}

export const BillsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(BillsPageInternal);
