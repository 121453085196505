import { Employee } from "@farmact/model/src/model/Employee";
import { Order } from "@farmact/model/src/model/Order";
import { MouseEventHandler } from "react";
import * as Modal from "@/components/FaModal";
import { ModalSpinner } from "@/components/ModalSpinner/ModalSpinner";
import { OrderFlowReadyState } from "@/components/orders/Tasks/orderFlowReadyState";
import { ProvideStopOrderContext } from "./StopOrderContext/StopOrderContext";
import { StopOrderModalContent } from "./StopOrderModalContent/StopOrderModalContent";
import { StopOrderPayload, useStopOrder } from "./useStopOrder";
import "./stopOrderModal.scss";

export type StopOrderModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    order: Order | undefined;
    onStop?: () => void;
    askForConfirmation: boolean;
    onClick?: MouseEventHandler<HTMLIonModalElement>;
    options?: {
        waitForDatabaseCommit?: boolean;
        employee?: Employee;
    };
};

export const StopOrderModal = (props: StopOrderModalProps) => {
    const stopOrder = useStopOrder(props.order);

    const handleStop = async (payload: StopOrderPayload) => {
        await stopOrder.stop(payload, props.options);
        props.onDismiss();
        props.onStop?.();
    };

    return (
        <Modal.Root isOpen={props.isOpen} onDismiss={props.onDismiss}>
            {props.order && (
                <ProvideStopOrderContext
                    enabled={props.isOpen}
                    orderId={props.order.id}
                    switch={{
                        [OrderFlowReadyState.INITIALIZING]: <ModalSpinner text="Auftrag stoppen wird vorbereitet" />,
                    }}>
                    <StopOrderModalContent
                        onDismiss={props.onDismiss}
                        onStop={handleStop}
                        askForConfirmation={props.askForConfirmation}
                    />
                </ProvideStopOrderContext>
            )}
        </Modal.Root>
    );
};
