import { lazy } from "react";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const BankConnectionRedirectPageContent = lazy(() => import("./BankConnectionRedirectPageContent"));

export function BankConnectionRedirectPage() {
    return (
        <DefaultPage>
            <BankConnectionRedirectPageContent />
        </DefaultPage>
    );
}
