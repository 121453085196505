import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "clsx";
import { Children, ReactElement, cloneElement, isValidElement, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { MenuBadge } from "./MenuBadge/MenuBadge";
import "./sideMenuTile.scss";

type SubMenuProps = {
    isOpen?: boolean;
    isInSplitPane: boolean;
};

type SideMenuTileProps = {
    clickTarget: string;
    name: string;
    icon: any;
    badgeNumber?: number;
    isInSplitPane?: boolean;
    menuIsOpen?: boolean;
    pathMatch?: string;

    children?: ReactElement<SubMenuProps> | ReactElement<SubMenuProps>[];
};

export function SideMenuTile(props: SideMenuTileProps) {
    const location = useLocation();

    const submenuPathOpen = !!matchPath(location.pathname, {
        path: props.pathMatch ?? props.clickTarget,
        exact: false,
    });

    const [subMenuIsOpen, setSubMenuIsOpen] = useState(submenuPathOpen);

    useEffect(() => {
        setSubMenuIsOpen(submenuPathOpen);
    }, [submenuPathOpen]);

    return (
        <>
            <Link
                className={cn("side-menu-tile", submenuPathOpen && "side-menu-tile--active")}
                to={props.clickTarget}
                onClick={e => {
                    if (props.isInSplitPane) {
                        e.stopPropagation();
                    }
                }}>
                {props.isInSplitPane ? (
                    <div className="side-menu-tile__item">
                        <FontAwesomeIcon icon={props.icon} className="side-menu-tile__icon" />
                        {!!props.badgeNumber && (
                            <div
                                className={cn([
                                    "side-menu-tile__news",
                                    props.menuIsOpen && "side-menu-tile__news--hidden",
                                ])}
                            />
                        )}
                        <p>{props.name}</p>
                        {!!props.badgeNumber && (
                            <MenuBadge badgeNumber={props.badgeNumber} className="side-menu-tile__badge" />
                        )}
                    </div>
                ) : (
                    <div className="side-menu-tile__item">
                        <FontAwesomeIcon icon={props.icon} className="side-menu-tile__icon" />
                        <p>{props.name}</p>
                        {!!props.badgeNumber && (
                            <MenuBadge badgeNumber={props.badgeNumber} className="side-menu-tile__badge" />
                        )}
                    </div>
                )}
                {props.children && (
                    <>
                        <div
                            className={cn("side-menu-tile__toggle", subMenuIsOpen && "side-menu-tile__toggle--open")}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSubMenuIsOpen(prevState => !prevState);
                            }}>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </>
                )}
            </Link>
            {Children.map(
                props.children,
                child =>
                    isValidElement(child) &&
                    cloneElement(child, {
                        isOpen: (!props.isInSplitPane || (props.isInSplitPane && !!props.menuIsOpen)) && subMenuIsOpen,
                    })
            )}
        </>
    );
}
