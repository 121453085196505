"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevelopmentAndTestsService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class DevelopmentAndTestsService {
    /**
     * @returns any
     * @throws ApiError
     */
    static load() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/test/load',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    static sync() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/test/sync',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    static match() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/test/match',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    static clear() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/test/clear',
        });
    }
}
exports.DevelopmentAndTestsService = DevelopmentAndTestsService;
