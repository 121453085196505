import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { ComponentPropsWithRef, useState } from "react";
import { FaIcon } from "@/components/icons/FaIcon/FaIcon";
import { Input } from "@/components/inputs/Input/Input";
import "./passwordInput.scss";

type PasswordInputOwnProps = {
    value: string;
    onChange: (value: string) => void;
};

type PasswordInputProps = PasswordInputOwnProps & Omit<ComponentPropsWithRef<"input">, keyof PasswordInputOwnProps>;

export function PasswordInput(props: PasswordInputProps) {
    const { value, onChange, ...restProps } = props;
    const [visible, setVisible] = useState(false);

    return (
        <div className="password-input">
            <Input
                {...restProps}
                className="password-input__input"
                type={visible ? "text" : restProps.type ?? "password"}
                value={value}
                onChange={e => onChange(e.currentTarget.value)}
                placeholder={props.placeholder ?? "Passwort"}
            />
            <FaIcon
                className="password-input__icon"
                icon={visible ? faEyeSlash : faEye}
                onClick={() => setVisible(prev => !prev)}
            />
        </div>
    );
}
