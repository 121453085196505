import { Order } from "@farmact/model/src/model/Order";
import { ReactNode } from "react";
import { OrderFlowReadyState } from "@/components/orders/Tasks/orderFlowReadyState";
import { useStopOrderContextValues } from "@/components/orders/Tasks/StopOrderModal/useStopOrderContextValues";
import { InitializeStopOrderContextMachineCountersError } from "./useInitializeStopOrderContextMachineCounters";
import { InitializeStopOrderContextTaskRecordsError } from "./useInitializeStopOrderContextTaskRecords";
import { _StopOrderContext } from "./useStopOrderContext";

type ProvideStopOrderContextProps = {
    orderId: Order["id"];
    enabled: boolean;

    switch?: ProvideStopOrderContextSwitch;

    children: ReactNode;
};

export function ProvideStopOrderContext(props: ProvideStopOrderContextProps) {
    const {
        driverQueries,
        setDriverQueries,
        note,
        setNote,
        machineCounterValues,
        setMachineCounterValues,
        taskRecords,
        setTaskRecords,
        order,
        readyState,
        initializationError,
    } = useStopOrderContextValues({ orderId: props.orderId, enabled: props.enabled });

    if (OrderFlowReadyState.ERROR && initializationError) {
        return <>{props.switch?.[OrderFlowReadyState.ERROR]?.(initializationError) ?? null}</>;
    }

    if (readyState === OrderFlowReadyState.INITIALIZING) {
        return <>{props.switch?.[OrderFlowReadyState.INITIALIZING] ?? null}</>;
    }

    if (readyState === OrderFlowReadyState.READY && order) {
        return (
            <_StopOrderContext.Provider
                value={{
                    order,
                    readyState,
                    //
                    driverQueries,
                    setDriverQueries,
                    note,
                    setNote,
                    //
                    machineCounterValues,
                    setMachineCounterValues,
                    //
                    taskRecords,
                    setTaskRecords,
                }}>
                {props.children}
            </_StopOrderContext.Provider>
        );
    }

    return null;
}

type ProvideStopOrderContextSwitch = {
    [OrderFlowReadyState.INITIALIZING]?: ReactNode;
    [OrderFlowReadyState.ERROR]?: (error: StopOrderContextInitializationError) => ReactNode;
};

type StopOrderContextInitializationError =
    | InitializeStopOrderContextMachineCountersError
    | InitializeStopOrderContextTaskRecordsError;
