import { AdminLevel } from "@farmact/model/src/model/adminTools/AdminLevel";
import { CompanyRoles, CustomAppUserClaims, CustomerRoles } from "@farmact/model/src/model/AppUser";
import { ParsedToken, User } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";

type UseCustomClaimsReturn = readonly [claims: CustomAppUserClaims | undefined, loading: boolean];

export function useCustomClaims(authUser: User | null | undefined): UseCustomClaimsReturn {
    const [claimsLoading, setClaimsLoading] = useState(true);
    const [customClaims, setCustomClaims] = useState<CustomAppUserClaims>();
    const [claimsIssuanceTime, setClaimsIssuanceTime] = useState(0);
    const [userMeta] = useDocumentData(authUser?.uid ? Firebase.instance().getAppUserMeta(authUser.uid) : undefined, [
        authUser?.uid,
    ]);

    const tokenLastChanged = userMeta?.tokenLastChanged;
    const currentTokenIsOutdated = !!(tokenLastChanged && claimsIssuanceTime && tokenLastChanged >= claimsIssuanceTime);

    useEffect(() => {
        // Scenario 1: user is logged out
        if (authUser !== null) {
            return;
        }
        if (!customClaims) {
            return;
        }
        setCustomClaims(undefined);
        setClaimsIssuanceTime(0);
        setClaimsLoading(false);
        Firebase.instance().forceUpdateUsersCompanyId("");
    }, [authUser, customClaims]);

    const updateClaims = useCallback(async (authUser: User, forceRefresh: boolean) => {
        const freshTokenResult = await authUser.getIdTokenResult(forceRefresh);
        const freshClaims = freshTokenResult.claims;

        // even though the typing indicates that iat is a string, it's in fact a number
        const freshTokenIssueTime = Number(freshClaims.iat);

        const updatedClaims = constructClaims(freshClaims);
        setCustomClaims(constructClaims(freshClaims));
        setClaimsIssuanceTime(freshTokenIssueTime);
        Firebase.instance().forceUpdateUsersCompanyId(updatedClaims.defaultCompanyId ?? "");
    }, []);

    useEffect(() => {
        // Scenario 2: initial claim setting for logged in user
        if (authUser && !customClaims) {
            updateClaims(authUser, false);
        }
    }, [authUser, customClaims, updateClaims]);

    useEffect(() => {
        // Scenario 3: token is outdated
        if (authUser && currentTokenIsOutdated) {
            updateClaims(authUser, true);
        }
    }, [authUser, currentTokenIsOutdated, updateClaims]);

    return [customClaims, claimsLoading];
}

function constructClaims(newClaims: ParsedToken): CustomAppUserClaims {
    const refreshedDefaultCompanyId = newClaims?.defaultCompanyId;
    const refreshedRoles = newClaims?.roles;
    const refreshedCustomerIds = newClaims?.customerIds;
    const refreshedAdminLevel = newClaims?.adminLevel;
    const refreshedInactiveCompanyIds = newClaims?.inactiveCompanyIds;

    return {
        defaultCompanyId: typeof refreshedDefaultCompanyId === "string" ? refreshedDefaultCompanyId : undefined,
        roles: typeof refreshedRoles === "object" ? (refreshedRoles as CompanyRoles) : undefined,
        customerIds: typeof refreshedCustomerIds === "object" ? (refreshedCustomerIds as CustomerRoles) : undefined,
        adminLevel: typeof refreshedAdminLevel === "string" ? (refreshedAdminLevel as AdminLevel) : undefined,
        inactiveCompanyIds: Array.isArray(refreshedInactiveCompanyIds) ? refreshedInactiveCompanyIds : [],
    };
}
