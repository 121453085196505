// rewrite OpenAPI BASE URL during development
import { OpenAPI as InvoiceApi } from "@farmact/invoice-api-client/lib/core/OpenAPI";
import { environment } from "@/config/config";

// must be equal to the value in vite.config.ts
const localInvoiceApiPath = "/localApi/invoices";

export function setupInvoiceApi() {
    if (environment == "local") {
        InvoiceApi.BASE = localInvoiceApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        InvoiceApi.BASE = "https://dev.api.farmact.de/invoices";
    }

    if (environment === "beta") {
        InvoiceApi.BASE = "https://beta.api.farmact.de/invoices";
    }

    if (environment === "prod") {
        InvoiceApi.BASE = "https://api.farmact.de/invoices";
    }

    // this call might be used to determine if the client is supported or not
    // (using allowedClients object)
    //  ======================================
    //   export declare type AboutMessage = {
    //     version: string;
    //     commitSHA: string;
    //     buildTimestamp: string;
    //     allowedClients: Array<string>;
    // };
    //  ======================================
    // AboutService.get();
}
