import { HOME, SIGNIN } from "@farmact/model/src/constants/farmActAppRoutes";
import { Redirect } from "react-router";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { getEmployeeIsImplicitOwner } from "@/util/accessUtils";
import { useUserContext } from "./useUserContext";

export function withOnlyImplicitOwner() {
    return (Component: any) => {
        return (props: any) => {
            const { authUser, employee: loggedInEmployee } = useUserContext();
            const { companyMeta, operatingUnits } = useAppCompanyContext();

            if (authUser === undefined) {
                return null;
            }
            if (authUser === null) {
                return <Redirect push to={SIGNIN} />;
            }

            if (companyMeta?.isSuspended) {
                Firebase.instance().doSignOut();
                location.reload();
            }

            if (!loggedInEmployee) {
                return null;
            }

            if (!getEmployeeIsImplicitOwner(loggedInEmployee, operatingUnits)) {
                return <Redirect push to={HOME} />;
            }
            return <Component {...props} />;
        };
    };
}
