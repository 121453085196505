import { App } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

export function useNativeAppUrlOpenListener() {
    const history = useHistory();
    useEffect(() => {
        App.addListener("appUrlOpen", data => {
            const url = new URL(data.url);
            history.push(url.pathname + url.search);
        });
    }, [history]);
}
