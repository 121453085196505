import {
    BANKING_REDIRECT,
    LANDING,
    MAIL_ACTION,
    MAP_STRUCTURES_SHARING,
    ORDERS_PROGRESS_SHARING,
    SIGNIN,
    SIGNUP,
} from "@farmact/model/src/constants/farmActAppRoutes";

export const routesWithoutMenu = [
    LANDING,
    SIGNIN,
    SIGNUP,
    ORDERS_PROGRESS_SHARING,
    MAP_STRUCTURES_SHARING,
    MAIL_ACTION,
    BANKING_REDIRECT,
];
