import { Resource } from "@farmact/model/src/model/Resource";
import {
    AnyResourcePriceStructure,
    FixedResourcePrice,
    ResourcePrice,
    ResourcePriceCalculationType,
    ResourcePriceVariantsType,
    SimpleFixedResourcePrice,
    SimpleResourcePrice,
    SimpleSurchargeResourcePrice,
    SurchargeResourcePrice,
    VariantsFixedResourcePrice,
    VariantsResourcePrice,
    VariantsSurchargeResourcePrice,
} from "@farmact/model/src/model/ResourcePrice";
import { ResourceUsage } from "@farmact/model/src/model/ResourceUsage";
import { PriceStructureType } from "@farmact/model/src/model/services/ServicePriceBlocks";
import { round } from "@farmact/utils/src/numberUtils";
import { recordError } from "./recordError";

/**
 * Calculate the resource price per unit based on the used method (variants vs simple prices, fixed vs surcharge)
 * @returns
 * @param price
 * @param variantId
 */
export function getResourcePricePerUnit(
    price: ResourcePrice<ResourcePriceCalculationType>,
    variantId: string
): AnyResourcePriceStructure {
    const relevantPriceVariant = getSimplePriceVariant(price, variantId);
    if (!relevantPriceVariant) {
        recordError("Tried to find resource price for incompatible combination. Returning 0.", {
            price,
            variantId,
        });
        return { type: PriceStructureType.SIMPLE, price: 0 };
    }

    if (isFixedSimpleResourcePrice(relevantPriceVariant)) {
        return relevantPriceVariant.pricePerUnit;
    } else if (isSurchargeSimpleResourcePrice(relevantPriceVariant)) {
        return {
            type: PriceStructureType.SIMPLE,
            price: round(relevantPriceVariant.costPerUnit * (1 + relevantPriceVariant.surchargePercent / 100), {
                decimalDigits: 3,
            }),
        };
    }

    recordError("Tried to find resource price for incompatible combination. Returning 0.", {
        price,
        variantId,
    });
    return { type: PriceStructureType.SIMPLE, price: 0 };
}

export function getResourceCostPerUnit(
    resourcePrice: ResourcePrice<ResourcePriceCalculationType>,
    variantId: string
): number {
    if (isSimpleResourcePrice(resourcePrice)) {
        return resourcePrice.costPerUnit;
    }

    const relevantPriceVariant = getSimplePriceVariant(resourcePrice, variantId);
    if (!relevantPriceVariant) {
        recordError("Tried to find resource cost for incompatible combination. Returning 0.", {
            resourcePrice,
            variantId,
        });
        return 0;
    }
    return relevantPriceVariant.costPerUnit;
}

export function getResourceVskzMr(
    resourcePrice: ResourcePrice<ResourcePriceCalculationType>,
    variantId: string
): string | null {
    if (isSimpleResourcePrice(resourcePrice)) {
        return resourcePrice.vskz_mr;
    }

    const relevantPriceVariant = getSimplePriceVariant(resourcePrice, variantId);
    if (!relevantPriceVariant) {
        console.warn(
            "Tried to find resource cost for incompatible combination. Returning 0.",
            resourcePrice,
            variantId
        );
        return null;
    }
    return relevantPriceVariant.vskz_mr;
}

export function getSimplePriceVariant<T extends ResourcePriceCalculationType>(
    price: ResourcePrice<T>,
    variantId: string
): SimpleResourcePrice<T> | null {
    if (isSimpleResourcePrice(price)) {
        if (variantId === price.id) {
            return price;
        } else {
            return null;
        }
    } else if (isVariantsResourcePrice(price)) {
        for (const priceVariant of price.variants) {
            const foundSubVariant = getSimplePriceVariant(priceVariant, variantId);
            if (foundSubVariant) {
                return foundSubVariant;
            }
        }
        return null;
    }
    return null;
}

export function getAnyPriceVariant<T extends ResourcePriceCalculationType>(
    price: ResourcePrice<T>,
    variantId: string
): ResourcePrice<T> | null {
    if (variantId === price.id) {
        return price;
    }
    if (isVariantsResourcePrice(price)) {
        for (const priceVariant of price.variants) {
            if (priceVariant.id === variantId) {
                return priceVariant;
            }
            if (isVariantsResourcePrice(priceVariant)) {
                for (const variation of priceVariant.variants) {
                    if (variation.id === variantId) {
                        return variation;
                    }
                }
            }
        }
        return null;
    }
    return null;
}

export function getResourcePriceCalculationTypeName(calculationType: ResourcePriceCalculationType) {
    switch (calculationType) {
        case ResourcePriceCalculationType.FIXED:
            return "Festpreis";
        case ResourcePriceCalculationType.SURCHARGE:
            return "Aufschlag (in %)";
        default:
            return "";
    }
}

export function isFixedResourcePrice(price: ResourcePrice<ResourcePriceCalculationType>): price is FixedResourcePrice {
    return price.calculationType === ResourcePriceCalculationType.FIXED;
}

export function isSurchargeResourcePrice(
    price: ResourcePrice<ResourcePriceCalculationType>
): price is SurchargeResourcePrice {
    return price.calculationType === ResourcePriceCalculationType.SURCHARGE;
}

export function isSimpleResourcePrice<T extends ResourcePriceCalculationType>(
    price: ResourcePrice<T>
): price is SimpleResourcePrice<T> {
    return price.variantsType === ResourcePriceVariantsType.SIMPLE;
}

export function isVariantsResourcePrice<T extends ResourcePriceCalculationType>(
    price: ResourcePrice<T>
): price is VariantsResourcePrice<T> {
    return price.variantsType === ResourcePriceVariantsType.VARIANTS;
}

export function isFixedVariantsResourcePrice(
    price: ResourcePrice<ResourcePriceCalculationType>
): price is VariantsFixedResourcePrice {
    return isVariantsResourcePrice(price) && isFixedResourcePrice(price);
}

export function isSurchargeVariantsResourcePrice(
    price: ResourcePrice<ResourcePriceCalculationType>
): price is VariantsSurchargeResourcePrice {
    return isVariantsResourcePrice(price) && isSurchargeResourcePrice(price);
}

export function isFixedSimpleResourcePrice(
    price: ResourcePrice<ResourcePriceCalculationType>
): price is SimpleFixedResourcePrice {
    return isSimpleResourcePrice(price) && isFixedResourcePrice(price);
}

export function isSurchargeSimpleResourcePrice(
    price: ResourcePrice<ResourcePriceCalculationType>
): price is SimpleSurchargeResourcePrice {
    return isSimpleResourcePrice(price) && isSurchargeResourcePrice(price);
}

export function constructResourceName(resource: Resource, variantId: string) {
    if (resource.price.id === variantId) {
        return resource.name;
    }

    const variantName = constructResourceVariantName(resource, variantId);

    return variantName ? [resource.name, variantName].join(" / ") : resource.name;
}

export function constructResourceVariantName(resource: Resource, variantId: string) {
    if (!variantId || resource.price.id === variantId) {
        return "";
    }

    if (isVariantsResourcePrice(resource.price)) {
        for (const variant of resource.price.variants) {
            if (variant.id === variantId) {
                return variant.name;
            }
            if (isVariantsResourcePrice(variant)) {
                for (const variation of variant.variants) {
                    if (variation.id === variantId) {
                        return [variant.name, variation.name].join(" / ");
                    }
                }
            }
        }
    }

    return "";
}

export function transformResourcePrice(
    resourcePrice: ResourcePrice<ResourcePriceCalculationType>
): ResourcePrice<ResourcePriceCalculationType> {
    if (isFixedSimpleResourcePrice(resourcePrice)) {
        const price =
            resourcePrice.pricePerUnit.type === PriceStructureType.SIMPLE
                ? resourcePrice.pricePerUnit.price
                : resourcePrice.pricePerUnit.price[0].price;
        const priceCostRatio = price / (resourcePrice.costPerUnit || 1) - 1;
        return {
            id: resourcePrice.id,
            name: resourcePrice.name,
            calculationType: ResourcePriceCalculationType.SURCHARGE,
            variantsType: ResourcePriceVariantsType.SIMPLE,
            costPerUnit: resourcePrice.costPerUnit,
            surchargePercent: round(100 * priceCostRatio, {
                decimalDigits: 2,
            }),
            vskz_mr: resourcePrice.vskz_mr,
        };
    }

    if (isSurchargeSimpleResourcePrice(resourcePrice)) {
        return {
            id: resourcePrice.id,
            name: resourcePrice.name,
            calculationType: ResourcePriceCalculationType.FIXED,
            variantsType: ResourcePriceVariantsType.SIMPLE,
            costPerUnit: resourcePrice.costPerUnit,
            pricePerUnit: {
                price: round(resourcePrice.costPerUnit * (1 + (resourcePrice.surchargePercent ?? 0) / 100), {
                    decimalDigits: 5,
                }),
                type: PriceStructureType.SIMPLE,
            },
            vskz_mr: resourcePrice.vskz_mr,
        };
    }

    if (isFixedVariantsResourcePrice(resourcePrice)) {
        return {
            id: resourcePrice.id,
            name: resourcePrice.name,
            calculationType: ResourcePriceCalculationType.SURCHARGE,
            variantsType: ResourcePriceVariantsType.VARIANTS,
            variants: resourcePrice.variants.map(variant => transformResourcePrice(variant)),
            vskz_mr: resourcePrice.vskz_mr,
        };
    }

    if (isSurchargeVariantsResourcePrice(resourcePrice)) {
        return {
            id: resourcePrice.id,
            name: resourcePrice.name,
            calculationType: ResourcePriceCalculationType.FIXED,
            variantsType: ResourcePriceVariantsType.VARIANTS,
            variants: resourcePrice.variants.map(variant => transformResourcePrice(variant)),
            vskz_mr: resourcePrice.vskz_mr,
        };
    }
    recordError("Could not identify resource price type", resourcePrice);
    return resourcePrice;
}

export type ResourceUsageGroupByKeys = Array<keyof ResourceUsage>;
export const resourceUsageGroupBySnapshot: ResourceUsageGroupByKeys = [
    "resourceId",
    "resourceVariantId",
    "resourceName",
    "resourcePricePerUnit",
    "resourceCostsPerUnit",
    "resourceUnit",
    "vatPercentPoints",
];

export function getResourceUsageKey(
    resourceUsage: ResourceUsage,
    groupBy: ResourceUsageGroupByKeys = resourceUsageGroupBySnapshot
): string {
    const groupByValues: Array<string | number> = [];
    for (const key of groupBy) {
        if (key === "resourcePricePerUnit") {
            const priceStructure: AnyResourcePriceStructure = resourceUsage.resourcePricePerUnit;
            if (
                priceStructure.type === PriceStructureType.TIERED ||
                priceStructure.type === PriceStructureType.VOLUME
            ) {
                groupByValues.push(`${priceStructure.id}`);
            } else {
                groupByValues.push(`${priceStructure.price}`);
            }
        } else {
            const value = resourceUsage[key];
            groupByValues.push(`${value}`);
        }
    }

    return groupByValues.join("-").toLowerCase();
}
