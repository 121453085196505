import { Context, createContext } from "react";
import { PositionCallback } from "@/util/customHooks/geolocationHooks/useGeolocation";

type GeolocationContextType = {
    watchGeolocation: (positionCallback: PositionCallback, continueInBackground?: boolean) => () => void;
    openDeviceGeolocationSettings: () => void;
};

export const GeolocationContext: Context<GeolocationContextType> = createContext<GeolocationContextType>({
    watchGeolocation: () => () => {},
    openDeviceGeolocationSettings: () => {},
});
