import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const GasStationPageContent = lazy(() => import("./GasStationsPageContent"));

function GasStationPageInternal() {
    return (
        <DefaultPage>
            <GasStationPageContent />
        </DefaultPage>
    );
}

export const GasStationsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    GasStationPageInternal
);
