import { useState } from "react";

type UseOpenStateReturn<T> = {
    openState: OpenState<T>;
    //
    open: (data: T) => void;
    close: () => void;
};

export function useOpenState<T>(): UseOpenStateReturn<T> {
    const [openState, setOpenState] = useState<OpenState<T>>({
        open: false,
    });

    const open = (data: T) => {
        setOpenState({
            open: true,
            data: data,
        });
    };

    const close = () => {
        setOpenState({ open: false });
    };

    return {
        openState,
        //
        open,
        close,
    };
}

export type OpenState<T> =
    | {
          open: false;
      }
    | {
          open: true;
          data: T;
      };
