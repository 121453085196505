import { AnyDriverQuery, Order } from "@farmact/model/src/model/Order";
import { DriverQueryInputTime } from "@farmact/model/src/model/services/DriverQuery";
import { useEffect, useState } from "react";
import { filterDriverQueriesByInputTime } from "@/util/orderUtils";

type UseInitializeStopOrderContextDriverQueriesParams = {
    enabled: boolean;
    setDriverQueries: (driverQueries: AnyDriverQuery[]) => void;
    setNote: (note: string) => void;
};

type UseInitializeStopOrderContextDriverQueriesReturn = {
    loading: boolean;
};

export function useInitializeStopOrderContextDriverQueries(
    order: Order | null,
    params: UseInitializeStopOrderContextDriverQueriesParams
): UseInitializeStopOrderContextDriverQueriesReturn {
    const { enabled, setDriverQueries, setNote } = params;

    const [previousEnabled, setPreviousEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    if (previousEnabled && !params.enabled) {
        setLoading(false);
        setPreviousEnabled(false);
    }
    if (!previousEnabled && params.enabled) {
        setLoading(true);
        setPreviousEnabled(true);
    }

    useEffect(() => {
        if (!enabled || !order) {
            return;
        }

        setDriverQueries([
            ...filterDriverQueriesByInputTime(order.driverQueriesYesNo, DriverQueryInputTime.STOP),
            ...filterDriverQueriesByInputTime(order.driverQueriesSingleValue, DriverQueryInputTime.STOP),
            ...order.driverQueriesBeforeAfter,
            ...filterDriverQueriesByInputTime(order.driverQueriesResourceWithAmount, DriverQueryInputTime.STOP),
            ...filterDriverQueriesByInputTime(order.driverQueriesResourceOnly, DriverQueryInputTime.STOP),
        ]);
        setNote(order.other);
        setLoading(false);
    }, [enabled, setDriverQueries, order, setNote]);

    return {
        loading,
    };
}
