import { lazy } from "react";
import { loggedIn } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const UpdateInfoPageContent = lazy(() => import("./UpdateInfoPageContent"));

function UpdateInfoPageInternal() {
    return (
        <DefaultPage>
            <UpdateInfoPageContent />
        </DefaultPage>
    );
}

export const UpdateInfoPage = withAuthorization(loggedIn)(UpdateInfoPageInternal);
