export class Deferred<T> {
    public readonly promise: Promise<T>;
    private resolve: (value: T) => void = () => {};

    constructor() {
        this.promise = new Promise(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * Call to resolve the promise
     */
    done(value: T) {
        this.resolve(value);
    }
}
