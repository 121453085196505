import { AnyDriverQuery, TaskRecord } from "@farmact/model/src/model/Order";
import { DriverQueryInputTime } from "@farmact/model/src/model/services/DriverQuery";
import { MachineCounterValue } from "@/components/orders/Tasks/MachineCounters/useMachineCounterGroups";
import { useStopOrderContext } from "@/components/orders/Tasks/StopOrderModal/StopOrderContext/useStopOrderContext";
import { StopOrderPayload } from "@/components/orders/Tasks/StopOrderModal/useStopOrder";
import { useToastContext } from "@/components/ToastContext";
import { getDidFillDriverQuery } from "@/util/orderUtils";
import { StopOrderView, UseStopOrderViewsReturn } from "./useStopOrderViews";

type UseStopOrderValidationReturn = {
    validateView: (view: StopOrderView, payloadCandidate: StopOrderPayloadCandidate) => boolean;
    validateAll: (payloadCandidate: StopOrderPayloadCandidate) => StopOrderPayload | null;
};

export function useStopOrderValidation(views: UseStopOrderViewsReturn): UseStopOrderValidationReturn {
    const { onMessage: showMessage } = useToastContext();
    const { order } = useStopOrderContext();

    const validateDriverQueries = (payloadCandidate: StopOrderPayloadCandidate): boolean => {
        for (const driverQuery of payloadCandidate.driverQueries) {
            if (!getDidFillDriverQuery(driverQuery, DriverQueryInputTime.STOP)) {
                showMessage("Bitte fülle alle Fahrerabfragen aus.", "warning");
                return false;
            }
        }

        return true;
    };

    const validateNote = (payloadCandidate: StopOrderPayloadCandidate): boolean => {
        if (order.serviceSnapshot?.queryNote && !payloadCandidate.note) {
            showMessage("Bitte gib eine Notiz ein.", "warning");
            return false;
        }
        return true;
    };

    const validateMachineCounterValues = (payloadCandidate: StopOrderPayloadCandidate): boolean => {
        for (const machineCounter of payloadCandidate.machineCounterValues) {
            if (machineCounter.value === null) {
                showMessage("Bitte fülle alle Maschinenzähler aus.", "warning");
                return false;
            }
        }

        return true;
    };
    const validateTaskRecords = (payloadCandidate: StopOrderPayloadCandidate): boolean => {
        for (const taskRecord of payloadCandidate.taskRecords) {
            if (taskRecord.record === null) {
                showMessage("Bitte fülle die Auftragszusammenfassung aus.", "warning");
                return false;
            }
        }

        return true;
    };

    const validateView = (view: StopOrderView, payloadCandidate: StopOrderPayloadCandidate): boolean => {
        switch (view) {
            case StopOrderView.DRIVER_QUERIES:
                return validateDriverQueries(payloadCandidate);
            case StopOrderView.MACHINE_COUNTERS:
                return validateMachineCounterValues(payloadCandidate);
            case StopOrderView.TASK_RECORDS:
                return validateTaskRecords(payloadCandidate);
            default:
                return true;
        }
    };

    const validateAll = (payloadCandidate: StopOrderPayloadCandidate): StopOrderPayload | null => {
        if (!validateDriverQueries(payloadCandidate) || !validateNote(payloadCandidate)) {
            views.set(StopOrderView.DRIVER_QUERIES);
            return null;
        }

        if (!validateMachineCounterValues(payloadCandidate)) {
            views.set(StopOrderView.MACHINE_COUNTERS);
            return null;
        }

        if (!validateTaskRecords(payloadCandidate)) {
            views.set(StopOrderView.TASK_RECORDS);
            return null;
        }

        return {
            driverQueries: payloadCandidate.driverQueries,
            note: order.serviceSnapshot?.queryNote ? payloadCandidate.note : null,
            machineCounterValues: payloadCandidate.machineCounterValues,
            taskRecords: payloadCandidate.taskRecords,
        };
    };

    return {
        validateView,
        validateAll,
    };
}

export type StopOrderPayloadCandidate = {
    driverQueries: AnyDriverQuery[];
    note: string | null;
    machineCounterValues: MachineCounterValue[];
    taskRecords: TaskRecord[];
};
