import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import { useWatchNewVersion } from "@/util/customHooks/useWatchNewVersion";
import * as Toast from "./FaToast";
import { ToastMessage } from "./FaToast/FaToastRoot/FaToastRoot";

export function NewVersionToast() {
    const { dismiss, outdated, dismissed, update } = useWatchNewVersion();

    const message: ToastMessage = {
        id: "new-version",
        message: "Es gibt eine neue Version von FarmAct!",
        color: "primary",
    };

    const showToast = !dismissed && outdated;

    return (
        <Toast.Root duration={Infinity} message={showToast ? message : undefined} onDismiss={dismiss}>
            <Toast.Action altText={getNewVersionActionText() ?? "FarmAct aktualisieren"} onClick={update}>
                {getNewVersionActionText()}
            </Toast.Action>
            <Toast.Close>Ignorieren</Toast.Close>
        </Toast.Root>
    );
}

function getNewVersionActionText(): string | undefined {
    if (Capacitor.isNativePlatform()) {
        if (isPlatform("ios")) {
            return "Zum App Store";
        } else if (isPlatform("android")) {
            return "Zum Play Store";
        }

        return;
    }

    return "Neu laden";
}
