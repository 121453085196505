import { AnyDriverQuery, Order, TaskRecord } from "@farmact/model/src/model/Order";
import { createContext, useContext } from "react";
import { MachineCounterValue } from "@/components/orders/Tasks/MachineCounters/useMachineCounterGroups";
import { OrderFlowReadyState } from "@/components/orders/Tasks/orderFlowReadyState";

export function useStopOrderContext(): StopOrderContextProps {
    const context = useContext(_StopOrderContext);

    if (!context) {
        throw new Error("useStopOrderContext must be used inside a <StopOrderContext.Provider>");
    }

    return context;
}
export type StopOrderContextProps = {
    /**
     * Order that should be started
     */
    order: Order;
    //
    readyState: OrderFlowReadyState;
    //
    driverQueries: AnyDriverQuery[];
    setDriverQueries: (driverQueries: AnyDriverQuery[]) => void;
    note: string;
    setNote: (newNote: string) => void;
    //
    machineCounterValues: MachineCounterValue[];
    setMachineCounterValues: (machineCounterValues: MachineCounterValue[]) => void;
    //
    taskRecords: TaskRecord[];
    setTaskRecords: (taskRecords: TaskRecord[]) => void;
};
export const defaultStopOrderContext: StopOrderContextProps = {
    readyState: OrderFlowReadyState.READY,
    order: new Order(),
    driverQueries: [],
    setDriverQueries: () => {},
    note: "",
    setNote: () => {},
    machineCounterValues: [],
    setMachineCounterValues: () => {},
    taskRecords: [],
    setTaskRecords: () => {},
};
export const _StopOrderContext = createContext<StopOrderContextProps | null>(null);
