import { AppCompanyContextType } from "@/components/authentication/AppCompanyContext";
import { OrganizationContextType } from "@/components/organization/context/useOrganizationContext";
import { useFetchAppCompanyContextData } from "./useFetchAppCompanyContextData";
import { useFetchOrganizationContextData } from "./useFetchOrganizationContextData";

type UseLoadOrganizationDataReturn = {
    appCompanyContext: AppCompanyContextType;
    organizationContext: OrganizationContextType;
    loading: boolean;
    error: boolean;
};

export function useBaseData(): UseLoadOrganizationDataReturn {
    const organizationData = useFetchOrganizationContextData();

    const {
        appCompanyContext,
        loading: appCompanyContextLoading,
        error: appCompanyContextError,
    } = useFetchAppCompanyContextData();

    const loading =
        organizationData.machinesLoading ||
        organizationData.servicesLoading ||
        organizationData.employeesLoading ||
        organizationData.resourcesLoading ||
        organizationData.gasStationsLoading ||
        organizationData.loadingPointsLoading ||
        appCompanyContextLoading;

    return {
        appCompanyContext: {
            ...appCompanyContext,
        },
        organizationContext: {
            ...organizationData,
        },
        loading,
        error: appCompanyContextError,
    };
}
