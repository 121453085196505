import { UPDATES_INFO } from "@farmact/model/src/constants/farmActAppRoutes";
import { faArrowRight, faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import { useContext } from "react";
import { useHistory } from "react-router";
import { UserContext } from "@/components/authentication/Session/UserContext";
import * as Popover from "@/components/FaPopover";
import { smallScreenSize } from "@/constants/others";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import "./updateInfoButton.scss";

export const UpdateInfoButton = () => {
    const history = useHistory();

    const { authUser, appUser } = useContext(UserContext);
    const [newestUpdates] = useCollectionData(
        authUser ? Firebase.instance().getUpdateInfos(window.innerWidth < smallScreenSize ? 5 : 3) : undefined,
        [authUser?.uid]
    );

    const amountOfDisplayedUnseenUpdateInfos = newestUpdates.filter(updateInfo =>
        appUser?.unseenUpdateInfos.includes(updateInfo.id)
    ).length;

    return (
        <Popover.Root>
            <Popover.Trigger>
                <IonButton
                    className={clsx([
                        "update-info-button__icon",
                        appUser && appUser?.unseenUpdateInfos.length > 0 && "update-info-button__icon--new",
                    ])}>
                    <FontAwesomeIcon icon={faBell} size="lg" />
                </IonButton>
            </Popover.Trigger>
            <Popover.Content collisionPadding={4} align="end" className="update-info-button-popover">
                <h4 className="update-info-button-popover__header">
                    Benachrichtigungen
                    <Popover.Close>
                        <FontAwesomeIcon icon={faTimes} />
                    </Popover.Close>
                </h4>
                {newestUpdates.length > 0 ? (
                    newestUpdates.map(update => (
                        <Popover.Close
                            key={update.title}
                            className="update-info-button-popover__update"
                            onClick={() => {
                                history.push(UPDATES_INFO + "/detail/" + update.id);
                            }}>
                            <div className="update-info-button-popover__update-text">
                                <h4
                                    className={clsx([
                                        "update-info-button-popover__update-title",
                                        appUser?.unseenUpdateInfos.includes(update.id) &&
                                            "update-info-button-popover__update-title--new",
                                    ])}>
                                    {update.title}
                                </h4>
                                <p className="update-info-button-popover__update-teaser">{update.teaser}</p>
                            </div>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Popover.Close>
                    ))
                ) : (
                    <p className="update-info-button-popover__empty-text">Keine Update Benachrichtigungen</p>
                )}
                <Popover.Close
                    className={clsx([
                        "update-info-button-popover__footer",
                        (appUser?.unseenUpdateInfos.length ?? 0) > amountOfDisplayedUnseenUpdateInfos &&
                            "update-info-button-popover__footer--new",
                    ])}
                    onClick={() => {
                        history.push(UPDATES_INFO);
                    }}>
                    Alle ansehen
                </Popover.Close>
            </Popover.Content>
        </Popover.Root>
    );
};
