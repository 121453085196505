// rewrite OpenAPI BASE URL during development
import { OpenAPI as FinApi } from "@farmact/fin-service-client";
import { environment } from "@/config/config";

// must be equal to the value in vite.config.ts
const localFinApiPath = "/localApi/fin";

export function setupFinApi() {
    if (environment == "local") {
        FinApi.BASE = localFinApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        FinApi.BASE = "https://dev.api.fin.farmact.de";
    }

    if (environment === "beta") {
        FinApi.BASE = "https://beta.api.fin.farmact.de";
    }

    if (environment === "prod") {
        FinApi.BASE = "https://api.fin.farmact.de";
    }
}
