import cn from "clsx";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { MenuBadge } from "@/components/structure/Page/SideNav/SideMenuTile/MenuBadge/MenuBadge";
import "./subMenuItem.scss";

type SubMenuItemProps = {
    name: string;
    route: string;
    isInSplitPane: boolean;
    badgeNumber?: number;
};

export const SubMenuItem = (props: SubMenuItemProps) => {
    const location = useLocation();

    return (
        <Link
            className={cn("sub-menu-item", matchPath(location.pathname, props.route) && "sub-menu-item--active")}
            to={props.route}
            onClick={e => {
                if (props.isInSplitPane) {
                    e.stopPropagation();
                }
            }}>
            {props.name}
            {!!props.badgeNumber && <MenuBadge badgeNumber={props.badgeNumber} />}
        </Link>
    );
};
