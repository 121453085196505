import { IonSpinner } from "@ionic/react";
import { Threshold } from "@/components/Threshold/Threshold";
import "./modalSpinner.scss";

type ModalSpinnerProps = {
    text?: string;
    /**
     * Show spinner after a fixed amount of time (in ms).
     * Set to `0` to immediately show the spinner
     * @default 2000
     */
    threshold?: number;
};

export const ModalSpinner = (props: ModalSpinnerProps) => {
    const { threshold = 2000, text } = props;

    return (
        <Threshold threshold={threshold}>
            <div className="modal-spinner">
                <IonSpinner className="modal-spinner__spinner" />
                <span className="modal-spinner__text">{text || "Wird geladen"}</span>
            </div>
        </Threshold>
    );
};
