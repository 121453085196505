import { useEffect, useState } from "react";
import { Firebase } from "@/firebase";

export function useCompanyId() {
    const [companyId, setCompanyId] = useState<string>();
    useEffect(() => {
        const timeout = setInterval(() => {
            const newCompanyId = Firebase.instance().getCompanyId();
            if (companyId !== newCompanyId) {
                setCompanyId(newCompanyId);
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [companyId]);
    return companyId;
}
