import { DatevAuthenticationService } from "@farmact/datev-app-client";
import { DatevProperties } from "@farmact/model/src/model/DatevProperties";
import { Role } from "@farmact/model/src/model/Role";
import { useEffect, useState } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { useRole } from "@/util/customHooks/useRole";

export function DatevConnectionCheck() {
    const { operatingUnits, appCompany, companyMeta } = useAppCompanyContext();
    const [checkDone, setCheckDone] = useState(false);
    const appCompanyId = appCompany?.id;
    const { only } = useRole();
    useEffect(() => {
        if (!only(Role.OWNER, Role.ADMIN) || checkDone || !appCompanyId || !companyMeta?.datevEnabled) {
            return;
        }
        setCheckDone(true);
        (async function () {
            for (const operatingUnit of operatingUnits) {
                const { consultantNumber, clientNumber, status } =
                    await DatevAuthenticationService.checkConnectionStatus({
                        companyId: appCompanyId,
                        operatingUnitId: operatingUnit.id,
                    });

                const datevIsConnected = ["CONNECTED_INACTIVE", "CONNECTED_ACTIVE"].includes(status);
                if (
                    datevIsConnected &&
                    (operatingUnit.datev.consultantNumber !== consultantNumber ||
                        operatingUnit.datev.clientNumber !== clientNumber)
                ) {
                    const updatedDatevProperties: DatevProperties = {
                        ...operatingUnit.datev,
                        consultantNumber,
                        clientNumber,
                    };
                    Firebase.instance().updatePartialOperatingUnit(operatingUnit.id, {
                        datev: updatedDatevProperties,
                    });
                }
            }
        })();
    }, [appCompanyId, checkDone, companyMeta?.datevEnabled, only, operatingUnits]);
    return null;
}
