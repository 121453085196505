import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { useContext } from "react";
import { Redirect } from "react-router";
import { UserContext } from "./authentication/Session/UserContext";

export const LandingPage = () => {
    const authUser = useContext(UserContext);

    if (authUser.authUser) {
        return <Redirect to={Routes.HOME} />;
    } else if (authUser.authUser === null) {
        return <Redirect to={Routes.SIGNIN} />;
    } else {
        return null;
    }
};
