import { ReactNode, createContext, useContext } from "react";

export type AlertAction = {
    text: string;
    handler?: () => void;
    /** @default "dark" */
    color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";
};

export type PresentFaAlertOptions = {
    /** @defaul true */
    backdropDismiss?: boolean;

    title?: string;
    message: ReactNode;

    actions?: AlertAction[];

    className?: string;
};

type PresentFaAlert = (options: PresentFaAlertOptions) => void;
type DismissFaAlert = () => void;

type FaAlertContext = [PresentFaAlert, DismissFaAlert];

export const FaAlertContext = createContext<FaAlertContext>([() => {}, () => {}]);

export function useFaAlert() {
    return useContext(FaAlertContext);
}
