import { IonButton } from "@ionic/react";
import * as Modal from "@/components/FaModal";
import { isIOSApp } from "@/util/isIOSApp";
import { openLink } from "@/util/openLink";
import "./registrationModal.scss";

type RegistrationModalProps = {
    isOpen: boolean;
    onDidDismiss: () => void;
};

const contactUrl = "https://www.farmact.de/kontakt";

export const RegistrationModal = (props: RegistrationModalProps) => {
    return (
        <Modal.Root isOpen={props.isOpen} onDismiss={props.onDidDismiss} className="registration-modal">
            <Modal.Header>Registrieren</Modal.Header>
            <Modal.Content>
                <div className="registration-modal__content">
                    <p className="registration-modal__text ion-margin-bottom">
                        Gehörst du zu einem Betrieb, der bereits FarmAct nutzt? Dann bitte deinen Administrator, dich
                        einzuladen.
                    </p>
                    {!isIOSApp() && (
                        <p className="registration-modal__text ion-margin-top">
                            Ganz neu bei FarmAct?
                            <br /> Kontaktiere uns für einen kostenlosen Testzugang!
                        </p>
                    )}
                    {!isIOSApp() && (
                        <IonButton
                            className="registration-modal__button"
                            color="primary"
                            onClick={() => openLink(contactUrl)}>
                            Jetzt testen
                        </IonButton>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
};
