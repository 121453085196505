import { IonText } from "@ionic/react";
import { useStopOrderContext } from "@/components/orders/Tasks/StopOrderModal/StopOrderContext/useStopOrderContext";
import "./askForConfirmationSlide.scss";

export function AskForConfirmationSlide() {
    const { order } = useStopOrderContext();
    return (
        <div className="order-driver-queries-modal-content">
            <div className="ask-for-confirmation-slide__content">
                <p>
                    Möchtest du <strong>{order.name}</strong> <IonText color="danger">stoppen</IonText>?
                </p>
                <p>
                    Es können danach <strong>keine weiteren Aktivitäten</strong> hinzugefügt werden.
                </p>
            </div>
        </div>
    );
}
