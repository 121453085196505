import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const TasksPageContent = lazy(() => import("./TasksPageContent"));

function TasksPageInternal() {
    return (
        <DefaultPage>
            <TasksPageContent />
        </DefaultPage>
    );
}

export const TasksPage = withAuthorization(
    getAuthorizationGuard(
        Role.OWNER,
        Role.ADMIN,
        Role.PLANNER,
        Role.EMPLOYEE,
        Role.TEMPORARY_WORKER,
        Role.SUBCONTRACTOR
    )
)(TasksPageInternal);
