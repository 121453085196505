import { lazy } from "react";
import { loggedIn } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const NotFoundPageContent = lazy(() => import("./NotFoundPageContent"));

function NotFoundPageInternal() {
    return (
        <DefaultPage>
            <NotFoundPageContent />
        </DefaultPage>
    );
}

export const NotFoundPage = withAuthorization(loggedIn)(NotFoundPageInternal);
