import { Resource } from "@farmact/model/src/model/Resource";
import {
    ResourcePrice,
    ResourcePriceCalculationType,
    ResourceVariantIdentifier,
    VariantsResourcePrice,
} from "@farmact/model/src/model/ResourcePrice";
import { IonButton } from "@ionic/react";
import { useState } from "react";
import * as Modal from "@/components/FaModal";
import { ModalSelectButton } from "@/components/ModalSelectButton/ModalSelectButton";
import { isSimpleResourcePrice, isVariantsResourcePrice } from "@/util/resourceUtils";
import "./selectVariantModal.scss";

type SelectVariantModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    resource: Resource;
    onVariantSelect: (selection: ResourceVariantIdentifier) => void;
    allowSelectionOfBaseVariant?: boolean;
};

export const SelectVariantModal = (props: SelectVariantModalProps) => {
    const [selectedVariantWithVariations, setSelectedVariantWithVariations] =
        useState<VariantsResourcePrice<ResourcePriceCalculationType> | null>(null);

    const handleLetDriverSelectVariant = () => {
        props.onVariantSelect({ resourceId: props.resource.id, variantId: props.resource.price.id });
        handleDismiss();
    };

    const handleVariantSelect = (variant: ResourcePrice<ResourcePriceCalculationType>) => {
        if (isSimpleResourcePrice(variant)) {
            props.onVariantSelect({ resourceId: props.resource.id, variantId: variant.id });
            handleDismiss();
            return;
        }
        if (isVariantsResourcePrice(variant)) {
            setSelectedVariantWithVariations(variant);
        }
    };
    const handleCancelClick = () => {
        if (selectedVariantWithVariations) {
            setSelectedVariantWithVariations(null);
        } else {
            handleDismiss();
        }
    };

    const handleDismiss = () => {
        setSelectedVariantWithVariations(null);
        props.onDismiss();
    };

    return (
        <Modal.Root isOpen={props.isOpen} onDismiss={props.onDismiss}>
            <Modal.Header>Variante auswählen</Modal.Header>
            <Modal.Content>
                <div className="select-variant-modal__content">
                    {isSimpleResourcePrice(props.resource.price) && (
                        <ModalSelectButton color="primary" onClick={() => handleVariantSelect(props.resource.price)}>
                            {props.resource.name}
                        </ModalSelectButton>
                    )}
                    {!selectedVariantWithVariations && isVariantsResourcePrice(props.resource.price) && (
                        <>
                            {props.allowSelectionOfBaseVariant && (
                                <ModalSelectButton onClick={handleLetDriverSelectVariant}>
                                    Auswahl dem Fahrer überlassen
                                </ModalSelectButton>
                            )}
                            {props.resource.price.variants.map(variant => (
                                <ModalSelectButton key={variant.id} onClick={() => handleVariantSelect(variant)}>
                                    {variant.name}
                                </ModalSelectButton>
                            ))}
                        </>
                    )}
                    {selectedVariantWithVariations &&
                        selectedVariantWithVariations.variants.map(variation => (
                            <ModalSelectButton key={variation.id} onClick={() => handleVariantSelect(variation)}>
                                {variation.name}
                            </ModalSelectButton>
                        ))}
                </div>
            </Modal.Content>
            <Modal.Footer>
                <IonButton color="dark" onClick={handleCancelClick}>
                    {selectedVariantWithVariations ? "Zurück" : "Abbrechen"}
                </IonButton>
            </Modal.Footer>
        </Modal.Root>
    );
};
