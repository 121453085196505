"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class MailService {
    /**
     * Send a generic mail
     * @returns SendMailResponseDto
     * @throws ApiError
     */
    static sendMail({ companyId, operatingUnitId, subject, recipients, displayName, replyTo, formData, ccs, bccs, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/mails/send',
            query: {
                'ccs': ccs,
                'bccs': bccs,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
                'subject': subject,
                'recipients': recipients,
                'displayName': displayName,
                'replyTo': replyTo,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Send a receipt via mail
     * @returns SendMailResponseDto
     * @throws ApiError
     */
    static sendReceiptMail({ companyId, operatingUnitId, subject, recipients, displayName, replyTo, formData, ccs, bccs, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/mails/send/receipt',
            query: {
                'ccs': ccs,
                'bccs': bccs,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
                'subject': subject,
                'recipients': recipients,
                'displayName': displayName,
                'replyTo': replyTo,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}
exports.MailService = MailService;
