import { lazy } from "react";
import { loggedIn } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const HomePageContent = lazy(() => import("./HomePageContent"));

function HomePageInternal() {
    return (
        <DefaultPage>
            <HomePageContent />
        </DefaultPage>
    );
}

export const HomePage = withAuthorization(loggedIn)(HomePageInternal);
