import { Position } from "@capacitor/geolocation";
import { ActivityType } from "@farmact/model/src/model/Employee";
import { GeolocationTracking, VertexWithTime } from "@farmact/model/src/model/GeolocationTracking";
import { ActiveTime, Order } from "@farmact/model/src/model/Order";
import dayjs from "dayjs";
import { arrayUnion, getDocFromCache } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { v4 } from "uuid";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { ClientBatchedWrite } from "@/firebase/ClientBatchedWrite";
import { useDocumentData } from "@/firebase/dataHooks";
import { useCurrentActivity } from "@/util/customHooks/useCurrentActivity";
import { useGeolocationContext } from "@/util/geolocation/useGeolocationContext";
import { THRESHOLD_SUFFICIENT_ACCURACY, computeAveragePoint, isLocationTrackedActivity } from "./util";

const minimumPointsForAggregatingLocation = 4;
const minimumWaitTimeForNewLocationUpload = 5;

export function useGeolocationForOrderTracking() {
    const { watchGeolocation } = useGeolocationContext();
    const { appCompany } = useAppCompanyContext();

    const lastLocationUploadUnixTime = useRef<number>();
    const collectedLocations = useRef<VertexWithTime[]>([]);

    const { currentActivity } = useCurrentActivity();
    const [activeOrder] = useDocumentData(
        currentActivity?.type === ActivityType.ORDER
            ? Firebase.instance().getOrderRef(currentActivity.orderId)
            : undefined,
        [currentActivity]
    );

    const trackGeolocationForActivity = isLocationTrackedActivity(currentActivity, appCompany);

    const currentGeolocationTrackingId =
        activeOrder?.geoLocationTrackingIds &&
        activeOrder.geoLocationTrackingIds.length > 0 &&
        activeOrder.geoLocationTrackingIds[activeOrder.geoLocationTrackingIds.length - 1];

    const currentActiveTimeId = activeOrder?.activeTime[activeOrder.activeTime.length - 1]?.id;
    const activeOrderId = activeOrder?.id;
    useEffect(() => {
        if (!trackGeolocationForActivity || !currentGeolocationTrackingId || !activeOrderId || !currentActiveTimeId) {
            return;
        }
        collectedLocations.current = [];

        const positionCallback = (position: Position | null) => {
            if (currentActivity?.type !== ActivityType.ORDER) {
                return;
            }
            if (!position) {
                console.warn("error getting position");
                return;
            }
            if (position.coords.accuracy > THRESHOLD_SUFFICIENT_ACCURACY) {
                return;
            }

            const newlyCollectedLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                time: Math.floor(position.timestamp / 1000),
            };

            const updatedCollectedLocations = [...collectedLocations.current, newlyCollectedLocation];

            if (
                updatedCollectedLocations.length >= minimumPointsForAggregatingLocation &&
                (!lastLocationUploadUnixTime.current ||
                    dayjs().diff(dayjs.unix(lastLocationUploadUnixTime.current), "s") >
                        minimumWaitTimeForNewLocationUpload)
            ) {
                uploadLocation(updatedCollectedLocations, currentActivity.orderId, currentGeolocationTrackingId);
                lastLocationUploadUnixTime.current = dayjs().unix();
                collectedLocations.current = [];
                createNewTrackingChunkIfNecessary(activeOrderId, currentActiveTimeId, currentGeolocationTrackingId);
            } else {
                collectedLocations.current = updatedCollectedLocations;
            }
        };

        const removeWatcher = watchGeolocation(positionCallback, true);

        return () => {
            removeWatcher();
            if (collectedLocations.current.length > 0 && currentActivity?.type === ActivityType.ORDER) {
                uploadLocation(collectedLocations.current, currentActivity.orderId, currentGeolocationTrackingId);
            }

            collectedLocations.current = [];
        };
    }, [
        activeOrderId,
        currentActiveTimeId,
        currentActivity,
        currentGeolocationTrackingId,
        trackGeolocationForActivity,
        watchGeolocation,
    ]);
}

function uploadLocation(locations: VertexWithTime[], activeOrderId: string, activeGeolocationTrackingId: string) {
    if (locations.length === 0) {
        return;
    }
    const pointToAdd = computeAveragePoint(locations);

    if (pointToAdd) {
        Firebase.instance().addGeolocationTrackingData(activeOrderId, activeGeolocationTrackingId, [pointToAdd]);
    }
}

// lower values lead to less data usage but more documents to handle and more reads billed
// higher values lead to more data usage but fewer documents to handle and fewer reads billed
const GEOLOCATION_TRACKING_CHUNK_SIZE = 200;

async function createNewTrackingChunkIfNecessary(
    activeOrderId: Order["id"],
    currentActiveTimeId: ActiveTime["id"],
    currentGeolocationTrackingId: string
) {
    try {
        const batch = new ClientBatchedWrite();
        const currentGeolocationTracking = (
            await getDocFromCache(
                Firebase.instance().getGeoLocationTracking(activeOrderId, currentGeolocationTrackingId)
            )
        ).data();
        if (
            currentGeolocationTracking &&
            currentGeolocationTracking.trackingPlain.length >= GEOLOCATION_TRACKING_CHUNK_SIZE
        ) {
            const newTrackingRef = Firebase.instance().getGeoLocationTracking(activeOrderId, v4());
            batch.batch().set(
                newTrackingRef,
                new GeolocationTracking({
                    id: newTrackingRef.id,
                    employeeId: currentGeolocationTracking.employeeId,
                    runId: currentActiveTimeId,
                    trackingPlain: [],
                    start: dayjs().unix(),
                })
            );
            await Firebase.instance().updatePartialOrder(
                activeOrderId,
                { geoLocationTrackingIds: arrayUnion(newTrackingRef.id) as unknown as string[] },
                batch.batch()
            );
            batch.commit();
        }
    } catch (error) {
        console.warn("Could not determine current geolocation tracking from cache.", { error });
    }
}
