import { ReactNode } from "react";
import { useOpenState } from "@/util/customHooks/useOpenState";
import { FaAlert, FaAlertProps } from "./FaAlert";
import { FaAlertContext, PresentFaAlertOptions } from "./FaAlertContext";

export function FaAlertProvider(props: { children: ReactNode }) {
    const alertState = useOpenState<Omit<FaAlertProps, "isOpen" | "onDismiss">>();
    const presentAlert = (options: PresentFaAlertOptions) => {
        alertState.open({
            backdropDismiss: options.backdropDismiss,
            title: options.title,
            message: options.message,
            actions: options.actions,
            className: options.className,
        });
    };
    const alertProps = alertState.openState.open ? alertState.openState.data : { message: "" };

    return (
        <>
            <FaAlertContext.Provider value={[presentAlert, alertState.close]}>{props.children}</FaAlertContext.Provider>
            <FaAlert isOpen={alertState.openState.open} onDismiss={alertState.close} {...alertProps} />
        </>
    );
}
