import { KeepAwake } from "@capacitor-community/keep-awake";
import { useEffect } from "react";
import { recordError } from "@/util/recordError";

export function useNoSleep() {
    useEffect(() => {
        (async function keepAwake() {
            const isSupported = await KeepAwake.isSupported();
            if (!isSupported) {
                return;
            } else {
                KeepAwake.keepAwake().catch(error => {
                    console.warn(error);
                });

                return () => {
                    KeepAwake.allowSleep().catch(error => recordError("Error releasing wakelock", error));
                };
            }
        })();
    }, []);
}
