import { IonButton } from "@ionic/react";
import { ReactNode } from "react";
import * as Modal from "@/components/FaModal";
import { StopOrderDriverQueriesModalContent } from "@/components/orders/Tasks/OrderDriverQueriesModalContent/OrderDriverQueriesModalContent";
import { OrderMachineCountersModalContent } from "@/components/orders/Tasks/OrderMachineCountersModalContent/OrderMachineCountersModalContent";
import { OrderStopModalContent } from "@/components/orders/Tasks/OrderStopModalContent/OrderStopModalContent";
import { OrderTaskRecordsModalContent } from "@/components/orders/Tasks/OrderTaskRecordsModalContent/OrderTaskRecordsModalContent";
import { useStopOrderContext } from "@/components/orders/Tasks/StopOrderModal/StopOrderContext/useStopOrderContext";
import { StopOrderPayload } from "@/components/orders/Tasks/StopOrderModal/useStopOrder";
import { getCurrentlyActiveMachineVariants } from "@/util/orderUtils";
import { AskForConfirmationSlide } from "./AskForConfirmationSlide/AskForConfirmationSlide";
import { StopOrderPayloadCandidate, useStopOrderValidation } from "./useStopOrderValidation";
import { StopOrderView, UseStopOrderViewsReturn, useStopOrderViews } from "./useStopOrderViews";

type StopOrderModalContentProps = {
    onDismiss: () => void;
    onStop: (payload: StopOrderPayload) => void;
    askForConfirmation: boolean;

    /**
     * @default "Auftrag stoppen"
     */
    title?: string;
};

export function StopOrderModalContent(props: StopOrderModalContentProps) {
    const { title = "Auftrag stoppen" } = props;

    const {
        order,
        driverQueries,
        setDriverQueries,
        note,
        setNote,
        machineCounterValues,
        setMachineCounterValues,
        taskRecords,
        setTaskRecords,
    } = useStopOrderContext();

    const views = useStopOrderViews(props.askForConfirmation);
    const validation = useStopOrderValidation(views);

    const previousOrDismiss = () => {
        if (views.empty) {
            props.onDismiss();
        } else if (views.isFirst) {
            props.onDismiss();
        } else {
            views.previous();
        }
    };
    const nextOrStop = () => {
        const payloadCandidate: StopOrderPayloadCandidate = {
            driverQueries,
            note: order.serviceSnapshot?.queryNote ? note : null,
            taskRecords,
            machineCounterValues,
        };

        if (views.empty || views.isLast) {
            stopOrder(payloadCandidate);
        } else {
            if (!validation.validateView(views.active!, payloadCandidate)) {
                return;
            }

            views.next();
        }
    };

    const stopOrder = (payloadCandidate: StopOrderPayloadCandidate) => {
        const payload = validation.validateAll(payloadCandidate);
        if (!payload) {
            return;
        }

        props.onStop(payload);
    };

    return (
        <>
            <Modal.Header>{formatViewModalTitle(title, views.active)}</Modal.Header>

            <Modal.Content>
                {views.empty && views.active === null && <OrderStopModalContent onStop={props.onStop} />}

                {views.active === StopOrderView.CONFIRMATION && <AskForConfirmationSlide />}

                {views.active === StopOrderView.DRIVER_QUERIES && (
                    <StopOrderDriverQueriesModalContent
                        allDriverQueries={driverQueries}
                        onDriverQueriesChange={setDriverQueries}
                        queryNote={!!order.serviceSnapshot?.queryNote}
                        note={note}
                        onNoteChange={setNote}
                    />
                )}

                {views.active === StopOrderView.MACHINE_COUNTERS && (
                    <OrderMachineCountersModalContent
                        machineCounters={machineCounterValues}
                        onMachineCountersChange={setMachineCounterValues}
                        machines={order.machineSnapshots}
                        activeMachineVariants={getCurrentlyActiveMachineVariants(order)}
                    />
                )}

                {views.active === StopOrderView.TASK_RECORDS && (
                    <OrderTaskRecordsModalContent taskRecords={taskRecords} onTaskRecordsChange={setTaskRecords} />
                )}
            </Modal.Content>

            <Modal.Footer>
                <IonButton color="dark" onClick={previousOrDismiss}>
                    {getPreviousButtonText(views)}
                </IonButton>

                <IonButton
                    color="primary"
                    onClick={() => {
                        nextOrStop();
                    }}>
                    {getNextButtonText(views)}
                </IonButton>
            </Modal.Footer>
        </>
    );
}

function formatViewModalTitle(title: string, view: StopOrderView | null): string {
    switch (view) {
        case StopOrderView.DRIVER_QUERIES:
            return `${title} - Fahrerabfragen`;
        case StopOrderView.MACHINE_COUNTERS:
            return `${title} - Maschinenzähler`;
        case StopOrderView.TASK_RECORDS:
            return `${title} - Zusammenfassung`;
        default:
            return title;
    }
}

function getPreviousButtonText(views: UseStopOrderViewsReturn): ReactNode {
    if (views.empty) {
        return "Abbrechen";
    }
    if (views.isFirst) {
        return "Abbrechen";
    }

    return "Zurück";
}

function getNextButtonText(views: UseStopOrderViewsReturn): ReactNode {
    if (views.empty) {
        return "Stoppen";
    }
    if (views.isLast) {
        return "Stoppen";
    }

    return "Weiter";
}
