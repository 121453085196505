import { faArrowRight, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import * as Popover from "@/components/FaPopover";
import { HubspotConversationsProvider } from "@/components/hubspot/HubspotConversationsProvider/HubspotConversationsProvider";
import { useHubspotConversationsContext } from "@/components/hubspot/utils/useHubspotConversationsContext";
import { useToastContext } from "@/components/ToastContext";
import { openLink } from "@/util/openLink";
import "./helpButton.scss";

export function HelpButton() {
    return (
        <HubspotConversationsProvider>
            <HelpButtonInternal />
        </HubspotConversationsProvider>
    );
}

const HELPDESK_URL = "https://handbuch.farmact.de";

function HelpButtonInternal() {
    const { onMessage } = useToastContext();
    const { toggleWidget, isOpen: hubspotChatOpen, unreadMessagesCount, isReady } = useHubspotConversationsContext();

    const handleToggleChat = () => {
        if (!hubspotChatOpen) {
            if (!window.HubSpotConversations?.widget?.status?.().loaded) {
                onMessage(
                    "Der Chat wird gerade noch geladen. Versuche es in ein paar Sekunden noch einmal.",
                    "warning"
                );
                return;
            }
        }
        toggleWidget();
    };

    return (
        <Popover.Root>
            <Popover.Trigger>
                <IonButton className={clsx("help-icon", unreadMessagesCount > 0 && "help-button--unread-messages")}>
                    <FontAwesomeIcon icon={faQuestion} size="lg" />
                </IonButton>
            </Popover.Trigger>
            <Popover.Content collisionPadding={4} className="help-button-popover">
                <span>Benötigst du Hilfe? In unserem Handbuch findest du Anleitungen und Hilfestellungen.</span>
                <Popover.Close className="help-button-popover__close" asChild>
                    <IonButton onClick={() => openLink(HELPDESK_URL)} className="help-button-popover__button">
                        Zum Handbuch
                        <FontAwesomeIcon icon={faArrowRight} className="help-popover__button-icon" />
                    </IonButton>
                </Popover.Close>
                <span>Alternativ kannst du auch direkt mit uns chatten.</span>
                <Popover.Close className="help-button-popover__close" asChild>
                    <IonButton onClick={handleToggleChat} disabled={!isReady} className="help-button-popover__button">
                        {hubspotChatOpen ? "Chat ausblenden" : "Chat anzeigen"}
                    </IonButton>
                </Popover.Close>
            </Popover.Content>
        </Popover.Root>
    );
}
