"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class AboutService {
    /**
     * ℹ️ Tell me more about this API
     * @returns AboutResponseDto
     * @throws ApiError
     */
    static about() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/about',
        });
    }
}
exports.AboutService = AboutService;
