import { AlertNotification } from "@farmact/model/src/model/AlertNotification";
import { Role } from "@farmact/model/src/model/Role";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "@/components/authentication/Session/UserContext";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useRole } from "@/util/customHooks/useRole";
import "./notification.scss";

const notificationDelay = 1000;
export const Notification = () => {
    const history = useHistory();
    const { authUser } = useContext(UserContext);
    const { role } = useRole();

    const [notifications] = useCollectionData(
        authUser?.uid &&
            role &&
            [Role.ADMIN, Role.OWNER, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER, Role.SUBCONTRACTOR].includes(
                role
            )
            ? Firebase.instance().getAllNewOrderNotificationsForUser(authUser?.uid)
            : undefined,
        [authUser?.uid, role]
    );

    const [notificationToShow, setNotificationToShow] = useState<AlertNotification | undefined>(undefined);

    const sortedNotifications = notifications.sort((c1: AlertNotification, c2: AlertNotification) =>
        dayjs(c1.updateTime).toISOString().localeCompare(dayjs(c2.updateTime).toISOString())
    );

    useEffect(() => {
        if (sortedNotifications.length > 0) {
            setNotificationToShow(sortedNotifications[0]);
        } else {
            setNotificationToShow(undefined);
        }
    }, [sortedNotifications]);

    const handleNotificationUpdate = async () => {
        await Firebase.instance().updateNotification(sortedNotifications[0].id, { seen: true });
    };

    const [visible, setVisible] = useState(false);
    const [shownText, setShownText] = useState(notificationToShow?.subHeadline ?? "");

    useEffect(() => {
        if (notificationToShow) {
            setVisible(false);
            setTimeout(() => {
                setShownText(notificationToShow?.subHeadline ?? "");
                setVisible(true);
            }, notificationDelay);
        } else {
            setVisible(false);
        }
    }, [notificationToShow]);

    const handleJumpToOrder = () => {
        handleNotificationUpdate();
        if (notificationToShow?.buttonLink) {
            history.push(notificationToShow.buttonLink);
        }
    };

    return (
        <div className={clsx("notification", visible && "notification--visible")}>
            <h5 className="notification__headline">{notificationToShow?.headline}</h5>
            {notificationToShow && (
                <>
                    <p className="notification__subheadline">{shownText}</p>
                    <div className="notification__button-row">
                        <IonButton onClick={handleNotificationUpdate} color={"medium"} size={"small"}>
                            {notificationToShow.dismissButtonLabel}
                        </IonButton>
                        <IonButton onClick={handleJumpToOrder} size={"small"}>
                            {notificationToShow.actionButtonLabel}
                        </IonButton>
                    </div>
                </>
            )}
        </div>
    );
};
