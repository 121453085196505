import { TollRecord } from "@farmact/model/src/model/TollRecord";
import { useIonViewDidLeave } from "@ionic/react";
import { useState } from "react";
import { TollPageContext } from "./TollPageContext";

type ProvideTollPageContextProps = {
    children: React.ReactNode;
};
export function ProvideTollPageContext(props: ProvideTollPageContextProps) {
    const [selection, _setSelection] = useState(new Map<string, TollRecord>());

    useIonViewDidLeave(() => {
        _setSelection(new Map());
    });

    const setSelection = (record: TollRecord, selected: boolean) => {
        _setSelection(prev => {
            const next = new Map(prev);
            if (selected) {
                next.set(record.id, record);
            } else {
                next.delete(record.id);
            }

            return next;
        });
    };

    const toggleSelection = (record: TollRecord) => {
        _setSelection(prev => {
            const next = new Map(prev);
            if (next.has(record.id)) {
                next.delete(record.id);
            } else {
                next.set(record.id, record);
            }

            return next;
        });
    };

    const resetSelection = () => {
        _setSelection(new Map());
    };

    const selectedTollRecords = Array.from(selection.values());

    return (
        <TollPageContext.Provider
            value={{ selection: selectedTollRecords, setSelection, toggleSelection, resetSelection }}>
            {props.children}
        </TollPageContext.Provider>
    );
}
