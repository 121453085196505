import { DriverQueryResourceOnly, DriverQueryResourceOnlyValue } from "@farmact/model/src/model/Order";
import { ResourceVariantIdentifier } from "@farmact/model/src/model/ResourcePrice";
import * as Item from "@/components/FaItem";
import { SingleResourceInput } from "@/components/inputs/resourceInputs/SingleResourceInput/SingleResourceInput";
import { computed } from "@/util/functions";
import {
    constructResourceName,
    getResourceCostPerUnit,
    getResourcePricePerUnit,
    getResourceVskzMr,
} from "@/util/resourceUtils";
import { useDriverQueryResourceOnlyResources } from "./useDriverQueryResourceOnlyResources";

type DriverQueryResourceOnlyInputItemProps = {
    driverQuery: DriverQueryResourceOnly;
    onChange: (updateData: Partial<DriverQueryResourceOnly>) => void;
};

export function DriverQueryResourceOnlyInputItem(props: DriverQueryResourceOnlyInputItemProps) {
    const resources = useDriverQueryResourceOnlyResources(props.driverQuery);

    const updateDriverQueryValue = (updateData: Partial<DriverQueryResourceOnlyValue>) => {
        props.onChange({
            value: {
                ...props.driverQuery.value,
                ...updateData,
            },
        });
    };

    const handleResourceChange = (updatedValue: ResourceVariantIdentifier | null) => {
        if (!updatedValue) {
            updateDriverQueryValue({
                resource: null,
            });
            return;
        }

        const resource = resources.find(resource => resource.id === updatedValue.resourceId);
        if (!resource) {
            return;
        }

        updateDriverQueryValue({
            resource: {
                id: resource.id,
                variantId: updatedValue.variantId,
                name: constructResourceName(resource, updatedValue.variantId),
                unit: resource.unit,
                vatPercentPoints: resource.vatPercentPoints,
                pricePerUnit: getResourcePricePerUnit(resource.price, updatedValue.variantId),
                costsPerUnit: getResourceCostPerUnit(resource.price, updatedValue.variantId),
                vskz_mr: getResourceVskzMr(resource.price, updatedValue.variantId),
            },
        });
    };

    const resourceVariantIdentifier = computed<ResourceVariantIdentifier | null>(() => {
        const value = props.driverQuery.value;
        if (!value.resource) {
            return null;
        }

        return {
            resourceId: value.resource.id,
            variantId: value.resource.variantId,
        };
    });

    return (
        <Item.Root>
            <Item.Label>Artikel</Item.Label>
            <Item.Content>
                <SingleResourceInput
                    availableResources={resources}
                    selectedResource={resourceVariantIdentifier}
                    onChange={handleResourceChange}
                />
            </Item.Content>
        </Item.Root>
    );
}
