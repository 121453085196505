import { lazy } from "react";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const OrdersProgressSharingPageContent = lazy(() => import("./OrdersProgressSharingPageContent"));

export function OrdersProgressSharingPage() {
    return (
        <DefaultPage>
            <OrdersProgressSharingPageContent />
        </DefaultPage>
    );
}
