import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CancellationBillPageContent = lazy(() => import("./CancellationBillPageContent"));

function BillCancellationPageInternal() {
    return (
        <DefaultPage>
            <CancellationBillPageContent />
        </DefaultPage>
    );
}

export const CancellationBillPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN))(
    BillCancellationPageInternal
);
