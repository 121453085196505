"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTranslationDictionary = exports.FErrorTranslator = void 0;
const f_error_1 = require("./f-error");
class FErrorTranslator {
    dictionary = exports.defaultTranslationDictionary;
    constructor(dictionary) {
        this.dictionary = { ...dictionary, ...exports.defaultTranslationDictionary };
    }
    translate(error, opts) {
        const unknownErrorMessage = 'Unbekannter Fehler.';
        /* check if error code is valid */
        if (!f_error_1.FError.isValidErrorCode(error))
            return unknownErrorMessage;
        let _error = null;
        _error = f_error_1.FError.fromFullErrorCode(error);
        const fullErrorCode = _error.fullErrorCode;
        let translation = this.dictionary[fullErrorCode];
        /* translation exists? */
        if (translation)
            return translation;
        /* fallback flags enabled? */
        if (!opts?.useGenericErrors && !opts?.useRawErrorFallback)
            return unknownErrorMessage;
        /* use generic errors */
        if (opts.useGenericErrors) {
            /* try to get area specific code and translation */
            if (_error.areaCode) {
                const areaErrorCode = f_error_1.FError.fromHttpStatusCodeAndAreaCodeOrNull(_error.httpStatusCode, _error.areaCode);
                if (areaErrorCode)
                    translation = this.dictionary[areaErrorCode.fullErrorCode];
                if (translation)
                    return translation;
            }
            if (_error.httpStatusCode) {
                const httpErrorCode = f_error_1.FError.fromHttpStatusCodeOrNull(_error.httpStatusCode);
                if (httpErrorCode)
                    translation = this.dictionary[httpErrorCode.fullErrorCode];
                if (translation)
                    return translation;
            }
        }
        /* fallback? */
        if (!translation && opts?.useRawErrorFallback) {
            return _error.message;
        }
        return translation ?? this.dictionary['default'] ?? 'Unbekannter Fehler.';
    }
    getCoverage() {
        const allErrorCodes = Object.keys(f_error_1.F_CODE).filter((error) => error.length > 7);
        const translatedErrorCodes = Object.keys(this.dictionary).filter((error) => error !== 'default');
        const missingErrorCodes = allErrorCodes.filter((code) => {
            if (!this.dictionary[code]) {
                return code;
            }
        });
        const unknownErrorCodes = translatedErrorCodes.filter((code) => {
            if (!allErrorCodes.includes(code)) {
                return code;
            }
        });
        /* calculate coverage */
        const coverage = Math.abs(Math.round((missingErrorCodes.length / allErrorCodes.length) * 100) - 100) || 0;
        return {
            coverage,
            message: `${coverage} % of all error codes are translated. Find the missing ones in the 'missingErrorCodes' property.`,
            missingErrorCodes,
            unknownErrorCodes,
            autoFixedDictionary: this.completeSortFormat(),
        };
    }
    completeSortFormat() {
        const allErrorCodes = Object.keys(f_error_1.F_CODE).filter((error) => error.length > 7);
        const translatedErrorCodes = Object.keys(this.dictionary).filter((error) => error !== 'default');
        const sorted = allErrorCodes.sort((a, b) => {
            const eA = f_error_1.FError.fromFullErrorCode(a);
            const eB = f_error_1.FError.fromFullErrorCode(b);
            const aa = `${eA.areaCode}_${eA.httpStatusCode}_${eA.errorCode ? eA.errorCode : '0000'}`;
            const bb = `${eB.areaCode}_${eB.httpStatusCode}_${eB.errorCode ? eB.errorCode : '0000'}`;
            return aa.localeCompare(bb);
        });
        const completedSortedFormatted = {};
        for (const code of sorted) {
            let e;
            if (translatedErrorCodes.includes(code))
                e = this.translate(code);
            else
                e = 'TODO: ' + f_error_1.FError.fromFullErrorCode(code).message;
            completedSortedFormatted[code] = e;
        }
        return completedSortedFormatted;
    }
}
exports.FErrorTranslator = FErrorTranslator;
exports.defaultTranslationDictionary = {
    default: 'Unbekannter Fehler.',
};
