import { Locale } from "@farmact/model/src/model/Locale";
import { LocaleFormatter, LocaleFormatterGenerator } from "@farmact/utils/src/formatters";
import { useContext, useMemo, useRef } from "react";
import { UserContext } from "@/components/authentication/Session/UserContext";

/**
 * Hook to get a user-locale aware formatter
 * @param formatterGenerator Memoized formatter generator (make sure it is stable and does not change between rerenders)
 * @param fallbackLocale
 */
export function useLocaleFormatter<T>(
    formatterGenerator: LocaleFormatterGenerator<T>,
    fallbackLocale: Locale = Locale.DE_DE
): LocaleFormatter<T> {
    const userContext = useContext(UserContext);
    const locale = userContext.appUser?.locale ?? fallbackLocale;

    const latestFormatterGenerator = useRef(formatterGenerator);
    latestFormatterGenerator.current = formatterGenerator;

    return useMemo(() => latestFormatterGenerator.current(locale), [locale]);
}
