import { Position } from "@capacitor/geolocation";
import { EmployeeLocation } from "@farmact/model/src/model/EmployeeLocation";
import { VertexWithTime } from "@farmact/model/src/model/GeolocationTracking";
import dayjs from "dayjs";
import { useContext, useEffect, useRef } from "react";
import { UserContext } from "@/components/authentication/Session/UserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { useCurrentActivity } from "@/util/customHooks/useCurrentActivity";
import { useGeolocationContext } from "@/util/geolocation/useGeolocationContext";
import { THRESHOLD_SUFFICIENT_ACCURACY, isLocationTrackedActivity } from "./util";

const minimumWaitTimeForNewLocation = 10;

export function useGeolocationForEmployeesLocation() {
    const { watchGeolocation } = useGeolocationContext();
    const { appCompany } = useAppCompanyContext();

    const lastEmployeeLocationUpdate = useRef<VertexWithTime>();

    const { employee, authUser } = useContext(UserContext);
    const { currentActivity } = useCurrentActivity();

    const trackGeolocationForActivity = isLocationTrackedActivity(currentActivity, appCompany);
    const employeeId = employee?.id;
    const appUserId = authUser?.uid;
    const employeeName = [employee?.firstName, employee?.lastName].join(" ");

    useEffect(() => {
        if (!trackGeolocationForActivity || !employeeId) {
            return;
        }

        const positionCallback = (position: Position | null) => {
            if (!position) {
                console.warn("error getting position");
                return;
            }
            if (position.coords.accuracy > THRESHOLD_SUFFICIENT_ACCURACY) {
                return;
            }

            const newlyCollectedLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                time: Math.floor(position.timestamp / 1000),
            };

            if (
                dayjs
                    .unix(newlyCollectedLocation.time)
                    .diff(dayjs.unix(lastEmployeeLocationUpdate.current?.time ?? 0), "s") >
                    minimumWaitTimeForNewLocation &&
                (lastEmployeeLocationUpdate.current?.lat !== newlyCollectedLocation.lat ||
                    lastEmployeeLocationUpdate.current?.lng !== newlyCollectedLocation.lng) &&
                employeeId
            ) {
                // update employee location at most every 10 seconds
                Firebase.instance().setEmployeeLocation(
                    new EmployeeLocation({
                        appUserId,
                        employeeId,
                        employeeName,
                        timestamp: dayjs.unix(newlyCollectedLocation.time).toISOString(),
                        lat: newlyCollectedLocation.lat,
                        lng: newlyCollectedLocation.lng,
                        activity: currentActivity,
                    })
                );
                lastEmployeeLocationUpdate.current = { ...newlyCollectedLocation };
            }
        };

        return watchGeolocation(positionCallback, true);
    }, [appUserId, currentActivity, employeeId, employeeName, trackGeolocationForActivity, watchGeolocation]);
}
