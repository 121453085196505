import { Capacitor } from "@capacitor/core";
import * as ROUTES from "@farmact/model/src/constants/farmActAppRoutes";
import { CustomAppUserClaims } from "@farmact/model/src/model/AppUser";
import { faArrowRight, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonInput, IonSpinner } from "@ionic/react";
import { SavePassword } from "capacitor-ios-autofill-save-password";
import { getDoc } from "firebase/firestore";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { Firebase } from "@/firebase";
import { isIOSApp } from "@/util/isIOSApp";
import { getMessageForAuthError } from "./util/signinUtils";
import "./signInPage.scss";

type LoginFormProps = {
    setShowSuspendedAlert: Dispatch<SetStateAction<boolean>>;
    setShowPasswordForgetModal: Dispatch<SetStateAction<boolean>>;
    setShowRegistrationModal: Dispatch<SetStateAction<boolean>>;
    setShowInactiveAlert: Dispatch<SetStateAction<boolean>>;
};

export const LoginForm = (props: LoginFormProps) => {
    const { setShowSuspendedAlert, setShowPasswordForgetModal, setShowRegistrationModal, setShowInactiveAlert } = props;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const { authUser } = useUserContext();

    const EMAIL_INPUT_NAME = "email";
    const PASSWORD_INPUT_NAME = "password";

    const history = useHistory();

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let email = "";
        let password = "";
        const form = event.target as HTMLFormElement;
        const emailInput = form.elements.namedItem(EMAIL_INPUT_NAME) as HTMLIonInputElement;
        const passwordInput = form.elements.namedItem(PASSWORD_INPUT_NAME) as HTMLIonInputElement;

        if (emailInput) {
            email = emailInput.value ? emailInput.value.toString().trim() : "";
        }
        if (passwordInput) {
            password = passwordInput.value ? passwordInput.value.toString() : "";
        }

        if (!emailInput || !passwordInput) {
            return;
        }

        setIsLoggingIn(true);
        try {
            const credentials = await Firebase.instance().doSignInWithEmailAndPassword(email.trim(), password);
            const companyMeta = (await getDoc(Firebase.instance().getCompanyMeta(undefined))).data();

            const claims = (await credentials.user.getIdTokenResult()).claims as CustomAppUserClaims;

            if (
                claims.defaultCompanyId &&
                claims.inactiveCompanyIds &&
                claims.inactiveCompanyIds.includes(claims.defaultCompanyId)
            ) {
                setShowInactiveAlert(true);
                await Firebase.instance().doSignOut();
                return;
            }

            if (companyMeta?.isSuspended) {
                setShowSuspendedAlert(true);
                await Firebase.instance().doSignOut();
                return;
            }
            if (Capacitor.getPlatform() === "ios") {
                await SavePassword.promptDialog({
                    username: email,
                    password: password,
                });
            }

            history.push(ROUTES.HOME);
        } catch (error: any) {
            setErrorMessage(getMessageForAuthError(error));
        } finally {
            setIsLoggingIn(false);
        }
    };

    if (!isLoggingIn && authUser) {
        return <Redirect to={ROUTES.HOME} />;
    }

    return (
        <form onSubmit={onSubmit} className="form">
            <h1 className="login-page__headline">Anmeldung</h1>
            <div className="login-page__text">E-Mail</div>
            <div className="form__input form__email-input">
                <IonInput
                    autocomplete="email"
                    autofocus
                    inputmode="email"
                    name={EMAIL_INPUT_NAME}
                    placeholder="meineEmail@email.de"
                    required
                    type="email"
                />
                <FontAwesomeIcon className="form__icon" icon={faUser} />
            </div>
            <div className="login-page__text">Passwort</div>
            <div className="form__input form__password-input">
                <IonInput
                    autocomplete="current-password"
                    name={PASSWORD_INPUT_NAME}
                    placeholder="••••••••••••"
                    required
                    type="password"
                />
                <FontAwesomeIcon className="form__icon" icon={faLock} />
            </div>
            {errorMessage && <p className="form__error login-page__text-register">{errorMessage}</p>}
            <p className="login-page__text-password" onClick={() => setShowPasswordForgetModal(true)}>
                Passwort vergessen?
            </p>
            <IonButton className="submit" type="submit" disabled={isLoggingIn}>
                <div className="submit__space-between">Anmelden</div>
                {isLoggingIn ? (
                    <IonSpinner className="submit__spinner" />
                ) : (
                    <FontAwesomeIcon className="submit__icon" icon={faArrowRight} />
                )}
            </IonButton>
            <div className="login-page__modal-links">
                <div>
                    <div className="login-page__text-register login-page__text-register--black">
                        Du hast noch keinen Account?
                    </div>
                    <p
                        className="login-page__text login-page__text-register"
                        onClick={() => setShowRegistrationModal(true)}>
                        {isIOSApp() ? "Hilfe" : "Jetzt registrieren"}
                    </p>
                </div>
            </div>
        </form>
    );
};
