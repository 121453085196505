import { useContext } from "react";
import { HubspotConversationsContext } from "./HubspotConversationsContext";

export function useHubspotConversationsContext() {
    const context = useContext(HubspotConversationsContext);

    if (context === null) {
        throw new Error("useHubspotConversations must be used within HubspotConversationsProvider");
    }

    return context;
}
