import { IonImg } from "@ionic/react";
import "./signInPage.scss";

export const SignInDecoration = () => {
    return (
        <>
            <div className="login-page__logo-container">
                <IonImg src="assets/farmact_logo_white.png" className="login-page__logo" alt="FarmAct logo" />
            </div>
            <h1 className="login-page__headline">
                Die flexibelste Software für <br />
                Lohnunternehmen & Transport
            </h1>
            <div className="login-page__image-container">
                <IonImg
                    src="assets/alldevices.png"
                    className="login-page__image"
                    alt="FarmAct tools displayed on Web, iOS & Android devices"
                />
            </div>
        </>
    );
};
