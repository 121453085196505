import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router";
import useScript from "react-script-hook";
import { HubspotContainer } from "@/components/hubspot/HubspotContainer/HubspotContainer";
import { HubspotConversationsContext } from "@/components/hubspot/utils/HubspotConversationsContext";

type HubspotConversationsProviderProps = {
    children: ReactNode;
};

export function HubspotConversationsProvider(props: HubspotConversationsProviderProps) {
    const [, error] = useScript({ src: "https://js-na1.hs-scripts.com/7567583.js" });
    useEffect(() => {
        if (error) {
            // TODO investigate (too much noise in bugsnag)
            // recordError("Error loading hubspot script", { error });
        }
    }, [error]);

    const history = useHistory();
    const [isReady, setIsReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { search, pathname } = useLocation();

    const hideWidget = () => {
        if (!isReady) {
            return;
        }
        setIsOpen(false);
    };

    const showWidget = () => {
        if (!isReady) {
            return;
        }

        const searchParams = new URLSearchParams(search);
        searchParams.set("inAppSupport", "true");
        history.replace(`${pathname}?${searchParams.toString()}`);
        window.HubSpotConversations.widget.refresh();
        window.HubSpotConversations.widget.open();

        setIsOpen(true);
    };

    const toggleWidget = () => {
        if (!isReady) {
            return;
        }
        if (isOpen) {
            hideWidget();
        } else {
            showWidget();
        }
    };

    useEffect(function init() {
        const onConversationsReady = () => {
            setIsReady(true);
        };
        if (window.HubSpotConversations) {
            onConversationsReady();
        } else {
            window.hsConversationsOnReady = [onConversationsReady];
        }
    }, []);

    useEffect(
        function addEventListeners() {
            if (!isReady) {
                return;
            }

            function listener(payload: { unreadCount: number }) {
                setUnreadMessagesCount(payload.unreadCount);
            }

            window.HubSpotConversations.on("unreadConversationCountChanged", listener);

            return () => {
                window.HubSpotConversations.off("unreadConversationCountChanged", listener);
            };
        },
        [isReady]
    );

    return (
        <HubspotConversationsContext.Provider value={{ isOpen, toggleWidget, unreadMessagesCount, isReady }}>
            {props.children}
            {createPortal(<HubspotContainer />, document.body)}
        </HubspotConversationsContext.Provider>
    );
}
