import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getAuth } from "firebase/auth";
import React, { ComponentType, ReactNode } from "react";
import { appVersion, bugSnagApiKey, environment } from "@/config/config";
import Firebase from "@/firebase/firebase";

type ErrorBoundaryProps = {
    children?: ReactNode;
};

export function setupErrorBoundary() {
    let ErrorBoundary: ComponentType<ErrorBoundaryProps> = (props: ErrorBoundaryProps) => {
        return <>{props.children}</>;
    };

    if (["prod", "beta"].includes(environment) && bugSnagApiKey) {
        Bugsnag.start({
            apiKey: bugSnagApiKey,
            appVersion: `${appVersion.major}.${appVersion.minor}.${appVersion.patch}`,
            releaseStage: environment,
            plugins: [new BugsnagPluginReact()],
            onError: event => {
                if (window.disableBugsnag) {
                    return false;
                }
                event.setUser(
                    getAuth().currentUser?.uid,
                    getAuth().currentUser?.email ?? undefined,
                    getAuth().currentUser?.displayName ?? undefined
                );
                event.addMetadata("companyId", { companyId: Firebase.instance().getUsersCompanyId() });
                console.error("Sending to Bugsnag. Original error:", event.originalError);
            },
        });

        const boundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
        if (boundary) {
            ErrorBoundary = boundary;
        }
    }

    return ErrorBoundary;
}
