import { DriverQueryYesNo } from "@farmact/model/src/model/Order";
import { IonToggle } from "@ionic/react";
import dayjs from "dayjs";
import * as Item from "@/components/FaItem";

type YesNoInputItemProps = {
    driverQuery: DriverQueryYesNo;
    onChange: (updateData: Partial<DriverQueryYesNo>) => void;
};

export const YesNoInputItem = (props: YesNoInputItemProps) => {
    const updateValue = (updatedValue: boolean | null) => {
        props.onChange({
            value: {
                ...props.driverQuery.value,
                timestamp: dayjs().toISOString(),
                checked: updatedValue,
            },
        });
    };

    return (
        <Item.Root>
            <Item.Label>Ja / Nein</Item.Label>
            <Item.Content>
                <IonToggle
                    checked={!!props.driverQuery.value.checked}
                    onIonChange={event => {
                        updateValue(event.detail.checked);
                    }}
                />
            </Item.Content>
        </Item.Root>
    );
};
