import { SplashScreen } from "@capacitor/splash-screen";
import { IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
import PasswordForgetModal from "@/components/authentication/PasswordForgetModal/PasswordForgetModal";
import { RegistrationModal } from "@/components/authentication/RegistrationModal/RegistrationModal";
import { FaAlert } from "@/components/FaAlert/FaAlert";
import { SignInDecoration } from "./SignInPageDecoration";
import { LoginForm } from "./SignInPageForm";
import "./signInPage.scss";

export const SignInPage = () => {
    const [showPasswordForgetModal, setShowPasswordForgetModal] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [showSuspendedAlert, setShowSuspendedAlert] = useState(false);
    const [showInactiveAlert, setShowInactiveAlert] = useState(false);

    SplashScreen.hide();

    return (
        <>
            <IonPage>
                <IonContent>
                    <div className="login-page">
                        <div className="login-page__grey-panel" />
                        <div className="login-page__green-panel" />
                        <div className="login-page__container">
                            <div className="login-page__grid">
                                <div className="login-page__row">
                                    <div className="login-page__decoration ">
                                        <SignInDecoration />
                                    </div>
                                    <div className="login-page__form">
                                        <LoginForm
                                            setShowSuspendedAlert={setShowSuspendedAlert}
                                            setShowPasswordForgetModal={setShowPasswordForgetModal}
                                            setShowRegistrationModal={setShowRegistrationModal}
                                            setShowInactiveAlert={setShowInactiveAlert}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>

            <PasswordForgetModal
                showModal={showPasswordForgetModal}
                onDidDismiss={() => setShowPasswordForgetModal(false)}
            />
            <RegistrationModal isOpen={showRegistrationModal} onDidDismiss={() => setShowRegistrationModal(false)} />

            <FaAlert
                isOpen={showSuspendedAlert}
                onDismiss={() => setShowSuspendedAlert(false)}
                title="Zugang gesperrt"
                message="Der Zugang wurde für dein Unternehmen deaktiviert. Bitte wende dich an info@farmact.de, um das Problem zu beheben."
                actions={[{ text: "Ok", handler: location.reload }]}
            />
            <FaAlert
                isOpen={showInactiveAlert}
                onDismiss={() => setShowInactiveAlert(false)}
                title="Deaktiviert"
                message="Dein Zugang wurde vom Betriebsleiter deaktiviert."
                actions={[{ text: "Ok", handler: location.reload }]}
            />
        </>
    );
};
