// rewrite OpenAPI BASE URL during development
import { OpenAPI as MailApi } from "@farmact/mail-service-client";
import { environment } from "@/config/config";

// must be equal to the value in vite.config.ts
const localMailApiPath = "/localApi/mails";

export function setupMailApi() {
    // set installed client version as default header for every request
    // this can be used on API side to allow or reject deprecated clients

    if (environment == "local") {
        MailApi.BASE = localMailApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        MailApi.BASE = "https://dev.api.mails.farmact.de";
    }

    if (environment === "beta") {
        MailApi.BASE = "https://beta.api.mails.farmact.de";
    }

    if (environment === "prod") {
        MailApi.BASE = "https://api.mails.farmact.de";
    }

    // this call might be used to determine if the client is supported or not
    // (using allowedClients object)
    //  ======================================
    //   export declare type AboutMessage = {
    //     version: string;
    //     commitSHA: string;
    //     buildTimestamp: string;
    //     allowedClients: Array<string>;
    // };
    //  ======================================
    // AboutService.get();
}
