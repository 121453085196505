"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionMatchService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class TransactionMatchService {
    /**
     * 💰 Get transaction matches
     * @returns GetTransactionMatchesResponseDto
     * @throws ApiError
     */
    static getTransactionMatches({ companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transaction-matches',
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 💰 Get more details about a specific transaction match
     * @returns GetTransactionMatchResponseDto
     * @throws ApiError
     */
    static getTransactionMatch({ id, companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/transaction-matches/{id}',
            path: {
                'id': id,
            },
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * 🗑️ Unmatch a transaction
     * @returns any
     * @throws ApiError
     */
    static deleteTransactionMatch({ id, companyId, operatingUnitId, recalculateBillStatus, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/transaction-matches/{id}',
            path: {
                'id': id,
            },
            query: {
                'recalculateBillStatus': recalculateBillStatus,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.TransactionMatchService = TransactionMatchService;
