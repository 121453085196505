import { useEffect, useState } from "react";

export function useNetworkStatus() {
    const [isOnline, setIsOnline] = useState(getIsOnline);

    useEffect(() => {
        const handleOfflineEvent = () => {
            setIsOnline(false);
        };
        const handleOnlineEvent = () => {
            setIsOnline(true);
        };
        window.addEventListener("offline", handleOfflineEvent);
        window.addEventListener("online", handleOnlineEvent);

        return () => {
            window.removeEventListener("offline", handleOfflineEvent);
            window.removeEventListener("online", handleOnlineEvent);
        };
    }, []);

    return isOnline;
}

function getIsOnline(): boolean {
    if (window.navigator !== undefined) {
        return window.navigator.onLine;
    }

    return true;
}
