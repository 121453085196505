import { createContext } from "react";

type HubspotConversationsContextType = {
    isReady: boolean;
    toggleWidget: () => void;
    isOpen: boolean;
    unreadMessagesCount: number;
};

export const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(null);
