import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import "./supportUserWarning.scss";

export function SupportUserWarning() {
    const { employee: loggedInEmployee } = useUserContext();
    const { companyMeta } = useAppCompanyContext();

    if (!companyMeta || companyMeta?.supportEmployeeId !== loggedInEmployee?.id) {
        return null;
    }

    return (
        <div className="support-user-warning">
            <div>Achtung! Du bist in einem Kundenaccount angemeldet!</div>
        </div>
    );
}
