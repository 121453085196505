import { lazy } from "react";
import { loggedIn } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const AccountPageContent = lazy(() => import("./AccountPageContent"));

function AccountPageInternal() {
    return (
        <DefaultPage>
            <AccountPageContent />
        </DefaultPage>
    );
}

export const AccountPage = withAuthorization(loggedIn)(AccountPageInternal);
