import { Customer } from "@farmact/model/src/model/Customer";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";

type UseCustomerReturn = {
    customer: Customer | undefined;
    loading: boolean;
};

export function useCustomer(customerId: string | undefined, options?: { companyId?: string }): UseCustomerReturn {
    const [customer, loading] = useDocumentData(
        customerId ? Firebase.instance().getCustomerRef(customerId, { companyId: options?.companyId }) : undefined,
        [customerId]
    );

    return {
        customer,
        loading,
    };
}
