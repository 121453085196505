import { query } from "firebase/firestore";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";

/**
 * This hook is needed on top level for correctly stopping an order in offline mode.
 * Without this hook, the external order data (i.e., machine counter trackings and time trackings) might not be available in the cache.
 * For reproducing the issue, start an order with either of the following traits while being online:
 * - has price blocks based on machine counters or machines with machine counters enabled
 * - has price blocks based on hectares (if you go this way, you need to start and stop field times while being online, too)
 *
 * Then go offline and stop the order.
 * You will see that - based on the scenario - machine counter trackings will not be queried or the hectare task record will not be pre-filled correctly, respectively.
 */
export function useOrderRunDataForCache(orderId: string | undefined, orderRunId: string | undefined) {
    // machineCounterTrackings for orderId
    const loadData = orderRunId && orderId;
    useCollectionData(loadData ? query(Firebase.instance().getAllMachineCounterTrackingsByOrder(orderId)) : undefined, [
        orderRunId,
    ]);
    // TimeTrackings For OrderRunId and OrderId
    useCollectionData(
        loadData ? query(Firebase.instance().getAllTimeTrackingsForOrderRunId(orderId, orderRunId)) : undefined,
        [orderRunId]
    );
}
