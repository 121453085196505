import { TollRecord } from "@farmact/model/src/model/TollRecord";
import { createScopedContext } from "@/util/context";

type TollPageContextProps = {
    selection: TollRecord[];
    setSelection: (record: TollRecord, selected: boolean) => void;
    toggleSelection: (record: TollRecord) => void;
    resetSelection: () => void;
};

export const [TollPageContext, useTollPageContext] = createScopedContext<TollPageContextProps>("TollPageContext");
