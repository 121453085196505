export enum OrderFlowReadyState {
    /**
     * The context is inactive
     */
    INACTIVE = "INACTIVE",
    /**
     * The context is active but has to prepare initial data
     */
    INITIALIZING = "INITIALIZING",
    /**
     * The context finished initializing but got an error
     */
    ERROR = "ERROR",
    /**
     * The context is active and ready. This is the only state subviews should become activ (display inputs etc)
     */
    READY = "READY",
}
