import { DriverQueryBeforeAfter, DriverQueryBeforeAfterValue } from "@farmact/model/src/model/Order";
import dayjs from "dayjs";
import * as Item from "@/components/FaItem";
import { NumberInput } from "@/components/inputs/NumberInput/NumberInput";

type BeforeAfterInputItemProps = {
    driverQuery: DriverQueryBeforeAfter;
    onChange: (updateData: Partial<DriverQueryBeforeAfter>) => void;
    features?: BeforeAfterInputItemFeatures;
};

export const BeforeAfterInputItem = (props: BeforeAfterInputItemProps) => {
    const updateValue = (updateData: Partial<DriverQueryBeforeAfterValue>) => {
        const current = props.driverQuery.value;

        props.onChange({
            value: {
                ...current,
                timestamp: dayjs().toISOString(),
                before: updateData.before !== undefined ? updateData.before : current.before ?? null,
                after: updateData.after !== undefined ? updateData.after : current.after ?? null,
            },
        });
    };

    const label = props.driverQuery.unit;

    return (
        <>
            {props.features?.showBefore && (
                <Item.Root>
                    <Item.Label>{label ? `${label} (vorher)` : "Vorher"}</Item.Label>
                    <Item.Content>
                        <NumberInput
                            placeholder="Vorher"
                            step={0.1}
                            currentValue={props.driverQuery.value.before}
                            onChange={before => updateValue({ before })}
                        />
                    </Item.Content>
                </Item.Root>
            )}
            {props.features?.showAfter && (
                <Item.Root>
                    <Item.Label>{label ? `${label} (nachher)` : "Nachher"}</Item.Label>
                    <Item.Content>
                        <NumberInput
                            placeholder="Nachher"
                            step={0.1}
                            currentValue={props.driverQuery.value.after}
                            onChange={after => updateValue({ after })}
                        />
                    </Item.Content>
                </Item.Root>
            )}
        </>
    );
};

type BeforeAfterInputItemFeatures = {
    showBefore?: boolean;
    showAfter?: boolean;
};
