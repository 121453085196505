import { ActivityPresetPurpose, AppCompany } from "@farmact/model/src/model/AppCompany";
import { Activity, ActivityType } from "@farmact/model/src/model/Employee";
import { VertexWithTime } from "@farmact/model/src/model/GeolocationTracking";

export const THRESHOLD_SUFFICIENT_ACCURACY = 100;
export const GEOLOCATION_OPTIONS = {
    timeout: 10000,
    maximumAge: 1000,
    enableHighAccuracy: true,
};

export function isLocationTrackedActivity(activity: Activity | null, appCompany: AppCompany | undefined): boolean {
    if (!activity) {
        return false;
    }
    if (!appCompany) {
        return false;
    }

    if (activity.type === ActivityType.INTERNAL) {
        const preset = appCompany.settings.internalActivityPresets.find(preset => {
            return preset.id === activity.internalActivityPresetId;
        });
        if (!preset) {
            return false;
        }

        return preset.purpose !== ActivityPresetPurpose.PAUSE;
    } else if (activity.type === ActivityType.ORDER) {
        const preset = appCompany.settings.orderActivityPresets.find(preset => {
            return preset.id === activity.orderActivityPresetId;
        });
        if (!preset) {
            return false;
        }

        return preset.purpose !== ActivityPresetPurpose.PAUSE;
    }

    return false;
}

export function computeAveragePoint(points: VertexWithTime[]) {
    const numberOfPoints = points.length;
    if (numberOfPoints < 1) {
        return;
    }
    return points.reduce(
        (prev, curr) => ({
            lat: prev.lat + curr.lat / numberOfPoints,
            lng: prev.lng + curr.lng / numberOfPoints,
            time: prev.time + curr.time / numberOfPoints,
        }),
        { lat: 0, lng: 0, time: 0 }
    );
}
