import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const OrdersAdministrationPageContent = lazy(() => import("./OrdersAdministrationPageContent"));

function OrdersAdministrationPageInternal() {
    return (
        <DefaultPage>
            <OrdersAdministrationPageContent />
        </DefaultPage>
    );
}

export const OrdersAdministrationPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    OrdersAdministrationPageInternal
);
