"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FOS`;
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FOS_400"] = "Bad Request";
    F_CODE["FOS_401"] = "Unauthorized";
    F_CODE["FOS_402"] = "Payment Required";
    F_CODE["FOS_403"] = "Forbidden";
    F_CODE["FOS_404"] = "Not Found";
    F_CODE["FOS_405"] = "Method Not Allowed";
    F_CODE["FOS_406"] = "Not Acceptable";
    F_CODE["FOS_407"] = "Proxy Authentication Required";
    F_CODE["FOS_408"] = "Request Timeout";
    F_CODE["FOS_409"] = "Conflict";
    F_CODE["FOS_410"] = "Gone";
    F_CODE["FOS_411"] = "Length Required";
    F_CODE["FOS_412"] = "Precondition Failed";
    F_CODE["FOS_413"] = "Payload Too Large";
    F_CODE["FOS_414"] = "URI Too Long";
    F_CODE["FOS_415"] = "Unsupported Media Type";
    F_CODE["FOS_416"] = "Range Not Satisfiable";
    F_CODE["FOS_417"] = "Expectation Failed";
    F_CODE["FOS_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FOS_421"] = "Misdirected Request";
    F_CODE["FOS_422"] = "Unprocessable Entity";
    F_CODE["FOS_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FOS_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FOS_425"] = "Too Early (RFC 8470)";
    F_CODE["FOS_426"] = "Upgrade Required";
    F_CODE["FOS_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FOS_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FOS_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FOS_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FOS_500"] = "Internal Server Error";
    F_CODE["FOS_501"] = "Not Implemented";
    F_CODE["FOS_502"] = "Bad Gateway";
    F_CODE["FOS_503"] = "Service Unavailable";
    F_CODE["FOS_504"] = "Gateway Timeout";
    F_CODE["FOS_505"] = "HTTP Version not supported";
    F_CODE["FOS_506"] = "Variant Also Negotiates";
    F_CODE["FOS_507"] = "Insufficient Storage";
    F_CODE["FOS_508"] = "Loop Detected";
    F_CODE["FOS_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FOS_510"] = "Not Extended";
    F_CODE["FOS_511"] = "Network Authentication Required";
    // =============
    // CUSTOM ERRORS
    // =============
    /*
     * Custom errors follow this convention: FOS_<HTTP_STATUS_CODE>_<AREA>_<SPECIFIC_ERROR_CODE>
     *
     * The <AREA> helps organizing the error codes into topics where the error originated
     *
     * AREAS:
     * 01: OCR Service
     * 02: Storage Service
     * 03: Invoice Parser Service
     *
     * The <SPECIFIC_ERROR_CODE> is a numeric code with 4 digits ranging from 0001 - 9999
     * giving detail about the specific error in the given <AREA>
     */
    /* 01: OCR Service */
    F_CODE["FOS_400_01"] = "General ocr service problem (bad request)";
    F_CODE["FOS_404_01"] = "General ocr service problem (not found)";
    F_CODE["FOS_500_01"] = "General ocr service problem (internal server error)";
    /* 02: Storage Service */
    F_CODE["FOS_500_02"] = "Storage service problem (internal server error)";
    /* 03: Invoice Parser Service */
    F_CODE["FOS_500_03"] = "Invoice parser service problem (internal server error)";
})(F_CODE || (exports.F_CODE = F_CODE = {}));
