import { DriverQuerySingleValue } from "@farmact/model/src/model/Order";
import dayjs from "dayjs";
import * as Item from "@/components/FaItem";
import { NumberInput } from "@/components/inputs/NumberInput/NumberInput";

type SingleValueInputItemProps = {
    driverQuery: DriverQuerySingleValue;
    onChange: (updateData: Partial<DriverQuerySingleValue>) => void;
    label?: string | null;
};

export const SingleValueInputItem = (props: SingleValueInputItemProps) => {
    const updateValue = (updatedValue: number | null) => {
        props.onChange({
            value: {
                ...props.driverQuery.value,
                timestamp: dayjs().toISOString(),
                value: updatedValue,
            },
        });
    };

    return (
        <Item.Root>
            <Item.Label>{props.label || "Wert"}</Item.Label>
            <Item.Content>
                <NumberInput
                    placeholder="Wert"
                    step={0.1}
                    currentValue={props.driverQuery.value.value}
                    onChange={value => updateValue(value)}
                />
            </Item.Content>
        </Item.Root>
    );
};
