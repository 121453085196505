import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import { MachineCounterTrackingSource } from "@farmact/model/src/model/MachineCounterTracking";
import { v4 } from "uuid";
import { MachineCounterQueries } from "@/components/orders/Tasks/MachineCounters/MachineCounterQueries/MachineCounterQueries";
import {
    MachineCounterValue,
    MachineCountersGroup,
} from "@/components/orders/Tasks/MachineCounters/useMachineCounterGroups";
import { computed } from "@/util/functions";
import { getMachineCounterTypeName } from "@/util/nameUtils/machineCounterTrackingNameUtils";
import "./orderMachineCountersModalContent.scss";

type OrderMachineCountersModalContentProps = {
    machineCounters: MachineCounterValue[];
    onMachineCountersChange: (machineCounters: MachineCounterValue[]) => void;
    activeMachineVariants: MachineVariantIdentifier[];
    machines: Machine[];
};

export const OrderMachineCountersModalContent = (props: OrderMachineCountersModalContentProps) => {
    const groups = groupMachineCounters(props.machineCounters, props.machines, props.activeMachineVariants);

    const handleValueChange = (machineCounterId: MachineCounterValue["id"], value: number | null) => {
        props.onMachineCountersChange(
            props.machineCounters.map(machineCounter => {
                if (machineCounter.id === machineCounterId) {
                    return {
                        ...machineCounter,
                        value,
                    };
                }

                return machineCounter;
            })
        );
    };

    return (
        <div className="order-machine-counters-modal-content">
            <p className="order-machine-counters-modal-content__hint">
                Folgende Maschinenzähler müssen beantwortet werden um fortzufahren
            </p>

            <MachineCounterQueries groups={groups} errors={[]} onChange={handleValueChange} />
        </div>
    );
};

const PRICE_TRACKING_MACHINE_COUNTER_ID = v4();

function groupMachineCounters(
    machineCounters: MachineCounterValue[],
    machines: Machine[],
    activeMachineVariants: MachineVariantIdentifier[]
): MachineCountersGroup[] {
    const machinesAssoc: Record<Machine["id"], MachineCounterValue[]> = {};
    const priceTrackingMachineCounters: MachineCounterValue[] = [];

    for (const machineCounter of machineCounters) {
        if (machineCounter.source.type === MachineCounterTrackingSource.MACHINE) {
            const key = machineCounter.source.machineId;
            machinesAssoc[key] = [...(machinesAssoc[key] ?? []), machineCounter];
        } else if (machineCounter.source.type === MachineCounterTrackingSource.PRICE_BLOCK) {
            priceTrackingMachineCounters.push(machineCounter);
        }
    }

    const groups: MachineCountersGroup[] = [];

    const sortedMachinesWithCounters = Object.entries(machinesAssoc)
        .map<[Machine | undefined, MachineCounterValue[]]>(([machineId, machineCounters]) => [
            machines.find(m => m.id === machineId),
            machineCounters.sort((a, b) =>
                getMachineCounterTypeName(a.type).localeCompare(getMachineCounterTypeName(b.type))
            ),
        ])
        .sort(([a], [b]) => {
            if (a === undefined) {
                return b === undefined ? 0 : 1;
            }
            if (b === undefined) {
                return -1;
            }
            return a.name.localeCompare(b.name);
        });

    for (const [machine, machineCounters] of sortedMachinesWithCounters) {
        const groupName = computed(() => {
            if (!machine) {
                return "Unbekannte Maschine";
            }
            const nameComponents = [machine.name];
            if (machine.variants.length > 1) {
                const activeMachineVariantId = activeMachineVariants.find(
                    variant => variant.machineId === machine.id
                )?.variantId;
                const activeMachineVariant = machine.variants.find(variant => variant.id === activeMachineVariantId);
                if (activeMachineVariant?.name) {
                    nameComponents.push(activeMachineVariant.name);
                }
            }
            return nameComponents.join(" / ");
        });

        groups.push({
            id: machine?.id ?? v4(),
            groupName,
            values: machineCounters,
        });
    }

    if (priceTrackingMachineCounters.length > 0) {
        groups.push({
            id: PRICE_TRACKING_MACHINE_COUNTER_ID,
            groupName: "Maschinenzähler ohne Maschine",
            values: priceTrackingMachineCounters,
        });
    }

    return groups;
}
