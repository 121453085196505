import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Role } from "@farmact/model/src/model/Role";
import { IonLoading, IonMenu, IonPage, IonSplitPane } from "@ionic/react";
import cn from "clsx";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ComponentPropsWithoutRef, MouseEventHandler, forwardRef, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { FaAlert } from "@/components/FaAlert/FaAlert";
import { routesWithoutMenu } from "@/components/structure/utils/routesWithoutMenu";
import { useNativeAppUrlOpenListener } from "@/util/customHooks/useAppUrlListener";
import { useCompanyId } from "@/util/customHooks/useCompanyId";
import { useRole } from "@/util/customHooks/useRole";
import { computed } from "@/util/functions";
import { isRouteTopLevel } from "@/util/headerHelpers";
import { SideNav } from "./SideNav/SideNav";
import "./menuPage.scss";

type MenuPageProps = ComponentPropsWithoutRef<"div"> & {
    contentId: string;
    appCompanyDataError: boolean;
    baseDataLoading: boolean;
};

enum FixedMenu {
    LOOSE,
    FIXED,
}

class FixedMenuStorage {
    public static readonly SETTING_KEY = "pinned-menu";

    static async getSetting(fallback = FixedMenu.LOOSE): Promise<FixedMenu> {
        const { value } = await Preferences.get({ key: FixedMenuStorage.SETTING_KEY });
        return value ? (FixedMenu as any)[value] : fallback;
    }

    static saveSetting(setting: FixedMenu) {
        Preferences.set({ key: FixedMenuStorage.SETTING_KEY, value: FixedMenu[setting] });
    }
}

const MINIMUM_LOAD_DURATION = 1500;
const NOTICE_DURATION = 10000;
const MAXIMUM_LOAD_DURATION = 60000;

export const MenuPage = forwardRef<HTMLDivElement, MenuPageProps>((props, ref) => {
    const { customClaims } = useUserContext();
    const { pathname } = useLocation();
    const [fixed, setFixed] = useState<FixedMenu | undefined>();
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const { role, isLoading: roleLoading } = useRole();
    const isCustomer = role === Role.CUSTOMER;
    const companyId = useCompanyId();
    const [previousRole, setPreviousRole] = useState<Role>();
    const [roleHasChanged, setRoleHasChanged] = useState(false);
    const [minimumLoadTimeOver, setMinimumLoadTimeOver] = useState(false);
    useNativeAppUrlOpenListener();

    useEffect(() => {
        const loadSettings = async () => {
            const setting = await FixedMenuStorage.getSetting();
            setFixed(setting);
        };
        if (!fixed) {
            loadSettings();
        }
    }, [fixed]);

    useEffect(() => {
        if (minimumLoadTimeOver) {
            return;
        }
        const timeout = setTimeout(() => {
            setMinimumLoadTimeOver(true);
        }, MINIMUM_LOAD_DURATION);
        return () => clearTimeout(timeout);
    }, [minimumLoadTimeOver]);

    const [noticeTimeOver, setNoticeTimeOver] = useState(false);
    useEffect(() => {
        if (noticeTimeOver) {
            return;
        }
        const timeout = setTimeout(() => {
            setNoticeTimeOver(true);
        }, NOTICE_DURATION);
        return () => clearTimeout(timeout);
    }, [noticeTimeOver]);

    const [maximumLoadTimeOver, setMaximumLoadTimeOver] = useState(false);
    useEffect(() => {
        if (maximumLoadTimeOver) {
            return;
        }
        const timeout = setTimeout(() => {
            setMaximumLoadTimeOver(true);
        }, MAXIMUM_LOAD_DURATION);
        return () => clearTimeout(timeout);
    }, [maximumLoadTimeOver]);

    useEffect(() => {
        return onAuthStateChanged(getAuth(), () => {
            setMinimumLoadTimeOver(false);
            setNoticeTimeOver(false);
            setMaximumLoadTimeOver(false);
        });
    }, []);

    useEffect(() => {
        setMinimumLoadTimeOver(false);
        setNoticeTimeOver(false);
        setMaximumLoadTimeOver(false);
    }, [companyId]);

    useEffect(() => {
        if (previousRole !== role) {
            if (previousRole) {
                setRoleHasChanged(true);
            }
            setPreviousRole(role);
        }
        setMinimumLoadTimeOver(false);
        setNoticeTimeOver(false);
        setMaximumLoadTimeOver(false);
    }, [previousRole, role]);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        const resizeListener = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    const menuRef = useRef<HTMLIonMenuElement>(null);

    const isRouteWithMenu = !routesWithoutMenu.includes(pathname);

    const isPreparingDataFromFirestore = props.baseDataLoading || roleLoading;

    const isLoading = !minimumLoadTimeOver || (isRouteWithMenu && isPreparingDataFromFirestore);
    const loadingMessage = noticeTimeOver
        ? "Einen Moment, wir holen gerade die neuesten Daten...<br/><br/>Das dauert länger als gewöhnlich. Bitte stelle sicher, dass du beim Starten der App eine Internetverbindung hast."
        : "Einen Moment, wir holen gerade die neuesten Daten...";

    const [hasRecordedError, setHasRecordedError] = useState(false);

    const toggleFixed = () => {
        setFixed(prev => {
            const next = prev === FixedMenu.LOOSE ? FixedMenu.FIXED : FixedMenu.LOOSE;
            FixedMenuStorage.saveSetting(next);
            return next;
        });
    };

    const handleSideContentClick: MouseEventHandler<HTMLElement> = e => {
        // NOTE: without this check the sidebar toggles when clicking or swiping on element inside a portal (e.g. alerts)
        if (e.target === e.currentTarget || e.currentTarget.contains(e.target as HTMLElement)) {
            if (windowWidth < 1500) {
                menuRef.current?.toggle();
            } else {
                toggleFixed();
            }
        }
    };

    const showErrorAlert = computed(() => {
        if (roleHasChanged) {
            return true;
        }
        if (!minimumLoadTimeOver || !isRouteWithMenu) {
            return false;
        }
        if (!isCustomer && !customClaims?.defaultCompanyId) {
            // currently belonging to no company, should be able to go to account to accept invitation
            return false;
        }
        return (!isCustomer && props.appCompanyDataError) || (maximumLoadTimeOver && isLoading);
    });

    if (showErrorAlert && !hasRecordedError) {
        // TODO: better error detection, we often arrive here even though everything works fine -> Unnecessary bugsnag noise
        // recordError(...)
        setHasRecordedError(true);
    }
    const alertHeader = roleHasChanged ? "Rolle geändert" : "Hoppla!";
    const alertMessage = roleHasChanged
        ? `Deine Rolle wurde geändert. Bitte ${Capacitor.isNativePlatform() ? "starte die App" : "lade die Seite"} neu.`
        : `Da hat etwas nicht funktioniert. Bitte ${
              Capacitor.isNativePlatform() ? "starte" : "lade"
          } die App neu und stelle sicher, dass du beim ${
              Capacitor.isNativePlatform() ? "Starten" : "Laden"
          } der App eine Internetverbindung hast.`;

    const showSpinner = !isRouteWithMenu && !showErrorAlert && (!minimumLoadTimeOver || isPreparingDataFromFirestore);

    return (
        <>
            <FaAlert
                isOpen={showErrorAlert}
                onDismiss={() => {}}
                title={alertHeader}
                message={alertMessage}
                backdropDismiss={false}
                // Apple's AppStore guidelines forbid closing an app programmatically
                actions={[
                    {
                        text: Capacitor.isNativePlatform() ? "Neu starten" : "Neu laden",
                        handler: () => location.reload(),
                    },
                ]}
            />
            {!isRouteWithMenu && props.children}
            {isRouteWithMenu && (
                <div className="menu-page-wrapper">
                    <IonLoading isOpen={showSpinner} message={loadingMessage} />
                    <IonPage>
                        <>
                            {windowWidth < 1500 && (
                                <IonMenu
                                    contentId={props.contentId}
                                    className="side-menu"
                                    ref={menuRef}
                                    onClick={handleSideContentClick}>
                                    <div className="side-menu__content">
                                        <SideNav />
                                    </div>
                                </IonMenu>
                            )}
                            <IonSplitPane
                                contentId={props.contentId}
                                className={cn([
                                    "menu-page",
                                    [Routes.LANDING, Routes.SIGNIN].includes(pathname) && "menu-page--home",
                                ])}
                                when={"lg"}
                                disabled={windowWidth < 1500 && !isRouteTopLevel(pathname)}>
                                <div
                                    className={cn([
                                        "side-content",
                                        windowWidth >= 1500 && fixed === FixedMenu.FIXED && "side-content--open",
                                    ])}
                                    ref={ref}
                                    onClick={handleSideContentClick}>
                                    <SideNav isOpen={windowWidth >= 1500 && fixed === FixedMenu.FIXED} isInSplitPane />
                                </div>
                                {props.children}
                            </IonSplitPane>
                        </>
                    </IonPage>
                </div>
            )}
        </>
    );
});
