import { Order } from "@farmact/model/src/model/Order";
import { getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Firebase } from "@/firebase";
import { recordError } from "@/util/recordError";

type UseLatestOrderParams = {
    enabled: boolean;
};

type UseLatestOrderReturn = {
    loading: boolean;
    order: Order | null;
};

export function useLatestOrder(orderId: Order["id"], params: UseLatestOrderParams): UseLatestOrderReturn {
    const { enabled } = params;

    const [previousEnabled, setPreviousEnabled] = useState(false);

    const [order, setOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState(true);

    if (previousEnabled && !enabled) {
        setLoading(false);
        setOrder(null);
        setPreviousEnabled(false);
    }
    if (!previousEnabled && enabled) {
        setLoading(true);
        setPreviousEnabled(true);
    }

    useEffect(() => {
        if (!enabled) {
            return;
        }

        let mounted = true;

        const loadOrder = async () => {
            try {
                const order = (await getDoc(Firebase.instance().getOrderRef(orderId))).data();
                if (mounted) {
                    setOrder(order ?? null);
                }
            } catch (error) {
                recordError("Failed to load order", {
                    orderId: orderId,
                });
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };
        loadOrder();

        return () => {
            mounted = false;
        };
    }, [enabled, orderId]);

    return {
        loading,
        order,
    };
}
