import { lazy } from "react";
import { onlyCustomer } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CollaborationPageContent = lazy(() => import("./CollaborationPageContent"));

function CollaborationPageInternal() {
    return (
        <DefaultPage>
            <CollaborationPageContent />
        </DefaultPage>
    );
}

export const CollaborationPage = withAuthorization(onlyCustomer)(CollaborationPageInternal);
