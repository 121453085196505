import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useHubspotConversationsContext } from "@/components/hubspot/utils/useHubspotConversationsContext";
import { isIOSApp } from "@/util/isIOSApp";
import "./hubspotContainer.scss";

const HUBSPOT_INLINE_EMBED_ELEMENT_ID = "hubspot-conversations-inline-embed-selector";

export function HubspotContainer() {
    const { toggleWidget, isOpen } = useHubspotConversationsContext();

    return (
        <div
            className={clsx(
                "hubspot-container",
                !isOpen && "hubspot-container--hidden",
                isIOSApp() && "hubspot-container--native-ios"
            )}
            id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}>
            <FontAwesomeIcon className="hubspot-container__close-icon" icon={faClose} onClick={toggleWidget} />
        </div>
    );
}
