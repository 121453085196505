import { IonButton } from "@ionic/react";
import { MouseEventHandler } from "react";
import * as Modal from "@/components/FaModal";
import { useControlWorkday } from "@/components/Home/WorkdayWidget/useControlWorkday";
import { InternalWorkNoteModal } from "@/components/internalWork/InternalWorkGrid/InternalWorkCard/InternalWorkNoteModal/InternalWorkNoteModal";
import { StopOrderModal } from "@/components/orders/Tasks/StopOrderModal/StopOrderModal";
import "./askToStopWorkdayModal.scss";

type AskToStopWorkdayModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    handleLogout: () => void;
    onClick?: MouseEventHandler<HTMLIonModalElement>;
};

export function AskToStopWorkdayModal(props: AskToStopWorkdayModalProps) {
    const controlWorkday = useControlWorkday({ waitForDatabaseCommit: true });

    const handleStop = async () => {
        await controlWorkday.stop();
        props.handleLogout();
        props.onDismiss();
    };

    return (
        <>
            <Modal.Root isOpen={props.isOpen} onDismiss={props.onDismiss} className="ask-to-stop-workday-modal">
                <Modal.Header>Arbeitstag beenden</Modal.Header>
                <Modal.Content>
                    <p className="ask-to-stop-workday-modal__text">
                        Dein Arbeitstag läuft noch, möchtest du ihn jetzt beenden?
                    </p>
                </Modal.Content>
                <Modal.Footer>
                    <IonButton color="secondary" onClick={handleStop}>
                        Beenden und Abmelden
                    </IonButton>

                    <IonButton
                        color="primary"
                        onClick={() => {
                            props.handleLogout();
                            props.onDismiss();
                        }}>
                        Abmelden
                    </IonButton>
                </Modal.Footer>
            </Modal.Root>

            <StopOrderModal {...controlWorkday.stopOrderModalProps} />
            <InternalWorkNoteModal {...controlWorkday.stopInternalWorkModalProps} />
        </>
    );
}
