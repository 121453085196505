"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FFS`;
/* forward stack trace */
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FFS_400"] = "Bad Request";
    F_CODE["FFS_401"] = "Unauthorized";
    F_CODE["FFS_402"] = "Payment Required";
    F_CODE["FFS_403"] = "Forbidden";
    F_CODE["FFS_404"] = "Not Found";
    F_CODE["FFS_405"] = "Method Not Allowed";
    F_CODE["FFS_406"] = "Not Acceptable";
    F_CODE["FFS_407"] = "Proxy Authentication Required";
    F_CODE["FFS_408"] = "Request Timeout";
    F_CODE["FFS_409"] = "Conflict";
    F_CODE["FFS_410"] = "Gone";
    F_CODE["FFS_411"] = "Length Required";
    F_CODE["FFS_412"] = "Precondition Failed";
    F_CODE["FFS_413"] = "Payload Too Large";
    F_CODE["FFS_414"] = "URI Too Long";
    F_CODE["FFS_415"] = "Unsupported Media Type";
    F_CODE["FFS_416"] = "Range Not Satisfiable";
    F_CODE["FFS_417"] = "Expectation Failed";
    F_CODE["FFS_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FFS_421"] = "Misdirected Request";
    F_CODE["FFS_422"] = "Unprocessable Entity";
    F_CODE["FFS_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FFS_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FFS_425"] = "Too Early (RFC 8470)";
    F_CODE["FFS_426"] = "Upgrade Required";
    F_CODE["FFS_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FFS_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FFS_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FFS_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FFS_500"] = "Internal Server Error";
    F_CODE["FFS_501"] = "Not Implemented";
    F_CODE["FFS_502"] = "Bad Gateway";
    F_CODE["FFS_503"] = "Service Unavailable";
    F_CODE["FFS_504"] = "Gateway Timeout";
    F_CODE["FFS_505"] = "HTTP Version not supported";
    F_CODE["FFS_506"] = "Variant Also Negotiates";
    F_CODE["FFS_507"] = "Insufficient Storage";
    F_CODE["FFS_508"] = "Loop Detected";
    F_CODE["FFS_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FFS_510"] = "Not Extended";
    F_CODE["FFS_511"] = "Network Authentication Required";
    // =============
    // CUSTOM ERRORS
    // =============
    /*
     * Custom errors follow this convention: FDI_<HTTP_STATUS_CODE>_<AREA>_<SPECIFIC_ERROR_CODE>
     *
     * The <AREA> helps organizing the error codes into topics where the error originated
     *
     * AREAS:
     * 01: General Service
     * 03: Firebase
     * 10: Institution
     * 11: BankConnection
     * 12: Transaction
     * 70: GoCardless
     *
     * The <SPECIFIC_ERROR_CODE> is a numeric code with 4 digits ranging from 0001 - 9999
     * giving detail about the specific error in the given <AREA>
     */
    /* 01: General Service */
    /* 10: Institution */
    /* 11: BankConnection */
    /* 12: Transaction */
    /* 70: GoCardless */
    F_CODE["FFS_400_70"] = "general gocardless problem (bad request)";
    F_CODE["FFS_404_70"] = "general gocardless problem (not found)";
    F_CODE["FFS_500_70"] = "general gocardless problem (internal server error)";
    F_CODE["FFS_503_70"] = "general gocardless problem (service unavailable)";
    F_CODE["FFS_504_70"] = "general gocardless problem (gateway timeout)";
    /* 71: GoCardless Institution */
})(F_CODE || (exports.F_CODE = F_CODE = {}));
