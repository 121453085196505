import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ServiceCatalogPageContent = lazy(() => import("./ServiceCatalogPageContent"));

function ServiceCatalogPageInternal() {
    return (
        <DefaultPage>
            <ServiceCatalogPageContent />
        </DefaultPage>
    );
}

export const ServiceCatalogPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER))(
    ServiceCatalogPageInternal
);
