import { IonButton } from "@ionic/react";
import clsx from "clsx";
import "./modalSelectButton.scss";

export function ModalSelectButton(props: React.ComponentProps<typeof IonButton>) {
    const { className, children, ...restProps } = props;
    return (
        <IonButton {...restProps} className={clsx("modal-select-button", className)}>
            {children}
        </IonButton>
    );
}
