import { IonButton } from "@ionic/react";
import { MouseEventHandler, useContext, useState } from "react";
import * as Modal from "@/components/FaModal";
import * as FormControl from "@/components/inputs/FormControl";
import { Textarea } from "@/components/inputs/Textarea/Textarea";
import { ToastContext } from "@/components/ToastContext";
import { useValidation } from "@/util/customHooks/validation/useValidation";
import "./internalWorkNoteModal.scss";

export type InternalWorkNoteModalProps = {
    isOpen: boolean;
    onSubmit: (note: string) => void;
    onDismiss: () => void;
    onClick?: MouseEventHandler<HTMLIonModalElement>;
};

export const InternalWorkNoteModal = (props: InternalWorkNoteModalProps) => {
    const [note, setNote] = useState("");
    const { onMessage } = useContext(ToastContext);

    const noteValidation = useValidation(note, note => {
        if (!note) {
            return "invalid";
        }
    });

    const handleSubmit = () => {
        if (!noteValidation.validate()) {
            onMessage("Trage bitte eine Notiz ein.", "warning");
            return;
        }
        props.onSubmit(note);
        setNote("");
    };

    const handleDismiss = () => {
        props.onDismiss();
        setNote("");
    };

    return (
        <Modal.Root isOpen={props.isOpen} onDismiss={handleDismiss} className="internal-work-note-modal">
            <Modal.Header>Interne Arbeit stoppen</Modal.Header>
            <Modal.Content>
                <div className="internal-work-note-modal__container">
                    <div className="internal-work-note-modal__text">
                        Bitte mache Angaben zur Arbeit, die gestoppt werden soll.
                    </div>
                    <FormControl.Root>
                        <FormControl.Label>Notiz</FormControl.Label>
                        <Textarea
                            placeholder="Notiz"
                            value={note}
                            onChange={event => {
                                setNote(event.currentTarget.value);
                            }}
                        />
                    </FormControl.Root>
                </div>
            </Modal.Content>
            <Modal.Footer>
                <IonButton color="dark" onClick={handleDismiss}>
                    Abbrechen
                </IonButton>
                <IonButton color="primary" onClick={handleSubmit}>
                    Stoppen
                </IonButton>
            </Modal.Footer>
        </Modal.Root>
    );
};
