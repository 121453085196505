import { lazy } from "react";
import { loggedIn } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const UpdateDetailsPageContent = lazy(() => import("./UpdateDetailsPageContent"));

function UpdateDetailsPageInternal() {
    return (
        <DefaultPage>
            <UpdateDetailsPageContent />
        </DefaultPage>
    );
}

export const UpdateDetailsPage = withAuthorization(loggedIn)(UpdateDetailsPageInternal);
