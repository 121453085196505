import { Context, Dispatch, SetStateAction, createContext } from "react";

export type DeviceSettings = {
    trackBackgroundGeolocation?: boolean;
};

type DeviceSettingsContextType = {
    deviceSettings: Partial<DeviceSettings>;
    setDeviceSettings: Dispatch<SetStateAction<DeviceSettings>>;
};

export const DeviceSettingsContext: Context<DeviceSettingsContextType> = createContext<DeviceSettingsContextType>({
    deviceSettings: {},
    setDeviceSettings: () => {},
});
