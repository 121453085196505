import { IonButton } from "@ionic/react";
import { StopOrderPayload } from "@/components/orders/Tasks/StopOrderModal/useStopOrder";
import "./orderStopModalContent.scss";

type OrderStopModalContentProps = {
    onStop: (payload: StopOrderPayload) => void;
};

/**
 * Safeguard, should never be shown.
 * @param props
 * @constructor
 */
export const OrderStopModalContent = (props: OrderStopModalContentProps) => {
    const handleStopClick = () => {
        props.onStop({
            driverQueries: [],
            note: null,
            machineCounterValues: [],
            taskRecords: [],
        });
    };

    return (
        <div className="order-stop-modal-content">
            <p className="order-stop-modal-content__hint">Der Auftrag kann ohne zusätzliche Eingaben gestoppt werden</p>

            <div className="order-stop-modal-content__container">
                <IonButton onClick={handleStopClick} className="order-stop-modal-content__stop-button">
                    Auftrag stoppen
                </IonButton>
            </div>
        </div>
    );
};
