"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FError = exports.F_CODE = void 0;
const _f_code_1 = require("./_f-code");
var _f_code_2 = require("./_f-code");
Object.defineProperty(exports, "F_CODE", { enumerable: true, get: function () { return _f_code_2.F_CODE; } });
class FError {
    fullErrorCode;
    httpStatusCode;
    areaCode = null;
    errorCode = null;
    message = 'undefined';
    details = null;
    constructor(errorCode, options) {
        let _errorCode = null;
        if (FError.isKey(errorCode))
            _errorCode = errorCode;
        if (FError.isValue(errorCode))
            _errorCode = this.extractFullErrorCode(errorCode);
        this.fullErrorCode = _errorCode;
        this.httpStatusCode = this.extractHttpStatusCode();
        this.areaCode = this.extractAreaCode();
        this.errorCode = this.extractErrorCode();
        this.message = this.assembleErrorMessage(_errorCode, options);
        this.details = options?.details || null;
    }
    static fromFullErrorCode(fullErrorCode, options) {
        return new this(fullErrorCode, options);
    }
    static fromAnyErrorCode(anyErrorCode, options) {
        if (this.isValidErrorCode(anyErrorCode)) {
            return this.fromFullErrorCode(anyErrorCode, options);
        }
        else
            return null;
    }
    static fromHttpStatusCode(httpStatusCode, options) {
        const fullErrorCode = _f_code_1.F_ERROR_PREFIX + '_' + httpStatusCode;
        if (this.isValidErrorCode(fullErrorCode)) {
            return FError.fromFullErrorCode(fullErrorCode, options);
        }
        else {
            const fullErrorCode = (_f_code_1.F_ERROR_PREFIX + '_' + 500);
            try {
                return FError.fromFullErrorCode(fullErrorCode, options);
            }
            catch (error) {
                throw Error('Cannot create error code from HTTP status code!');
            }
        }
    }
    static fromHttpStatusCodeOrNull(httpStatusCode, options) {
        const fullErrorCode = _f_code_1.F_ERROR_PREFIX + '_' + httpStatusCode;
        if (this.isValidErrorCode(fullErrorCode)) {
            return FError.fromFullErrorCode(fullErrorCode, options);
        }
        else
            return null;
    }
    static fromHttpStatusCodeAndAreaCode(httpStatusCode, areaCode, options) {
        const fullErrorCode = `${_f_code_1.F_ERROR_PREFIX}_${httpStatusCode}_${areaCode}`;
        if (this.isValidErrorCode(fullErrorCode)) {
            return FError.fromFullErrorCode(fullErrorCode, options);
        }
        else
            return this.fromHttpStatusCode(httpStatusCode);
    }
    static fromHttpStatusCodeAndAreaCodeOrNull(httpStatusCode, areaCode, options) {
        const fullErrorCode = `${_f_code_1.F_ERROR_PREFIX}_${httpStatusCode}_${areaCode}`;
        if (this.isValidErrorCode(fullErrorCode)) {
            return FError.fromFullErrorCode(fullErrorCode, options);
        }
        else
            return null;
    }
    extractFullErrorCode(errorCode) {
        const indexOf = Object.values(_f_code_1.F_CODE).indexOf(errorCode);
        const res = Object.keys(_f_code_1.F_CODE)[indexOf];
        if (res === undefined)
            return null;
        return res;
    }
    extractHttpStatusCode() {
        const httpStatusCodeRegex = /_\d{3}/;
        const httpStatusCode = httpStatusCodeRegex.exec(this.fullErrorCode);
        return +httpStatusCode[0].split('_')[1];
    }
    extractAreaCode() {
        const areaCodeRegex = /_\d{3}_\d{2}/;
        const areaCode = areaCodeRegex.exec(this.fullErrorCode);
        if (areaCode)
            return areaCode[0].split('_')[2];
        else
            return null;
    }
    extractErrorCode() {
        const errorCodeRegex = /_\d{4}/;
        const errorCode = errorCodeRegex.exec(this.fullErrorCode);
        if (errorCode)
            return errorCode[0].split('_')[1];
        else
            return null;
    }
    assembleErrorMessage(errorCode, options) {
        let res = '';
        const commonFullErrorCode = _f_code_1.F_ERROR_PREFIX + '_' + this.httpStatusCode;
        const commonErrorMessage = _f_code_1.F_CODE[commonFullErrorCode];
        res += commonErrorMessage;
        /* it is not a generic error */
        if (commonFullErrorCode != this.fullErrorCode)
            res += '. ' + _f_code_1.F_CODE[errorCode];
        if (options?.customMessage)
            res += '. ' + options.customMessage;
        return res;
    }
    /* type guards */
    static isValidErrorCode(code) {
        const exists = Object.keys(_f_code_1.F_CODE).indexOf(code) == -1 ? false : true;
        return exists;
    }
    static isKey(input) {
        return input.startsWith(_f_code_1.F_ERROR_PREFIX);
    }
    static isValue(input) {
        return !input.startsWith(_f_code_1.F_ERROR_PREFIX);
    }
}
exports.FError = FError;
