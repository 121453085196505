import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, isPlatform } from "@ionic/react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import * as Modal from "@/components/FaModal";
import { appVersion } from "@/config/config";
import { appStoreLinks } from "@/constants/links";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";
import { getAppBaseUrl } from "@/util/getAppBaseUrl";
import { openLink } from "@/util/openLink";
import { resetCacheAndReloadPage } from "@/util/resetCacheAndReloadPage";
import { compareVersions } from "@/util/version";
import "./unsupportedVersionModal.scss";

export const UnsupportedVersionModal = () => {
    const { appCompany } = useAppCompanyContext();
    const [appMeta] = useDocumentData(Firebase.instance().getAppMeta());

    const showUnsupportedVersionModal = !!(
        appMeta &&
        appCompany?.id &&
        appCompany.id !== APPLE_TEST_ACCOUNT_ID &&
        compareVersions(appVersion, appMeta.minimumSupportedVersion) < 0
    );

    const handleUpdateClick = () => {
        openLink(isPlatform("ios") ? appStoreLinks.ios : appStoreLinks.android);
    };

    return (
        <Modal.Root isOpen={showUnsupportedVersionModal}>
            <Modal.Header>Veraltete Version</Modal.Header>
            <Modal.Content>
                <div className="unsupported-version">
                    {isPlatform("mobileweb") || isPlatform("desktop") ? (
                        <div className="unsupported-version__reload">
                            Deine App-Version wird nicht mehr unterstützt. Bitte lade die Seite neu (Strg / Cmd + R).
                            <IonButton className="update-button" onClick={() => location.reload()}>
                                Jetzt neu laden
                            </IonButton>
                        </div>
                    ) : (
                        <>
                            <div className="unsupported-version__reload">
                                Deine App-Version wird nicht mehr unterstützt. Bitte lade die aktuellste Version
                                herunter.
                                <IonButton className="update-button" onClick={handleUpdateClick}>
                                    Jetzt aktualisieren{" "}
                                    <FontAwesomeIcon icon={faExternalLink} className="update-button__icon" />
                                </IonButton>
                            </div>
                            <div className="unsupported-version__guide-to-solution">
                                Falls in dem Store kein Update zu finden ist, gibt es{" "}
                                <a
                                    onClick={() => {
                                        openLink(
                                            "https://handbuch.farmact.de/update-kann-nicht-heruntergeladen-werden"
                                        );
                                    }}>
                                    hier
                                </a>{" "}
                                eine mögliche Lösung für das Problem.
                            </div>
                        </>
                    )}
                    <div className="unsupported-version__reset-cache">
                        Update geladen, aber du siehst immer noch dieses Fenster?
                        <IonButton onClick={() => resetCacheAndReloadPage()}>Nochmal versuchen</IonButton>
                    </div>
                    {isPlatform("mobile") && !isPlatform("mobileweb") && (
                        <div className="unsupported-version__link-to-web-app">
                            Du kannst die App auch vorübergehend über die Web-Version nutzen.
                            <IonButton onClick={() => openLink(getAppBaseUrl())}>Zur Web-Version</IonButton>
                        </div>
                    )}
                </div>
            </Modal.Content>
        </Modal.Root>
    );
};

const APPLE_TEST_ACCOUNT_ID = "2mNMxZb8k6Zgp9HJfGTN";
