import { Customer } from "@farmact/model/src/model/Customer";
import { WATER_FOR_LIQUID_MIXTURES } from "@farmact/model/src/model/LiquidMixture";
import { MachineCounterTracking } from "@farmact/model/src/model/MachineCounterTracking";
import { Order } from "@farmact/model/src/model/Order";
import { Refuel, ResourceUsageSource } from "@farmact/model/src/model/Refuel";
import { Resource, ResourceUnit } from "@farmact/model/src/model/Resource";
import { AnyResourcePriceStructure } from "@farmact/model/src/model/ResourcePrice";
import { ResourceUsage } from "@farmact/model/src/model/ResourceUsage";
import { TollRecord } from "@farmact/model/src/model/TollRecord";
import { notNullish } from "@farmact/model/src/utils/array";
import { QueryConstraint, getDocs, query, where } from "firebase/firestore";
import rfdc from "rfdc";
import { Firebase } from "@/firebase";
import { ClientBatchedWrite } from "@/firebase/ClientBatchedWrite";
import { getCurrentlyActiveTime } from "@/util/orderUtils";
import { ResourceUsageGroupByKeys, getResourceUsageKey, resourceUsageGroupBySnapshot } from "@/util/resourceUtils";

export function getTrackedAndQueryResourceUsages(order: Order): ResourceUsage[] {
    return [...order.resourceUsages, ...getDriverQueryResourceUsages(order)];
}

export function getDriverQueryResourceUsages(order: Order): ResourceUsage[] {
    const driverQueryResourceUsages: ResourceUsage[] = [];

    for (const driverQuery of order.driverQueriesResourceWithAmount) {
        if (driverQuery.value.usage) {
            driverQueryResourceUsages.push(driverQuery.value.usage);
        }

        driverQueryResourceUsages.push(...driverQuery.value.history.map(historyEntry => historyEntry.usage));
    }

    return driverQueryResourceUsages;
}

export type GroupedResourceUsage = {
    key: string;
    resourceId: string;
    variantId: string;
    resourceName: string;
    source: ResourceUsageSource;
    name: string;
    amount: number;
    unit: ResourceUnit;
    costs: number;
    price: AnyResourcePriceStructure;
    usages: ResourceUsage[];
    vatPercentPoints: number;
    vskz_mr: string | null;
};

/**
 * Group resource usages by it's resource
 * @param resourceUsages
 * @param resources List of all available resources
 * @param groupBy
 * @returns
 */
export function getGroupedResourceUsages(
    resourceUsages: ResourceUsage[],
    resources: Resource[],
    groupBy: ResourceUsageGroupByKeys = resourceUsageGroupBySnapshot
): GroupedResourceUsage[] {
    const groups = new Map<string, GroupedResourceUsage>();

    for (const resourceUsage of resourceUsages) {
        const resourceUsageKey = getResourceUsageKey(resourceUsage, groupBy);
        const group = groups.get(resourceUsageKey);

        if (group) {
            group.usages.push(resourceUsage);
        } else {
            const resourcesIncludingWater = [...resources, WATER_FOR_LIQUID_MIXTURES];
            const resource = resourcesIncludingWater.find(resource => resource.id === resourceUsage.resourceId);

            groups.set(resourceUsageKey, {
                key: resourceUsageKey,
                resourceId: resourceUsage.resourceId ?? "",
                variantId: resourceUsage.resourceVariantId ?? "",
                source: resourceUsage.source ?? ResourceUsageSource.COMPANY,
                resourceName: resourceUsage.resourceName,
                name: resource?.billDescription ?? "",
                unit: resourceUsage.resourceUnit,
                costs: resourceUsage.resourceCostsPerUnit,
                price: resourceUsage.resourcePricePerUnit,
                amount: 0,
                vatPercentPoints: resourceUsage.vatPercentPoints,
                vskz_mr: resourceUsage.vskz_mr ?? null,
                usages: [resourceUsage],
            });
        }
    }

    return Array.from(groups.values()).map(group => {
        return {
            ...group,
            amount: group.usages.reduce((acc, cur) => acc + cur.amount, 0),
        };
    });
}

export function getCurrentlyActiveCustomerId(order: Order) {
    const activeTime = getCurrentlyActiveTime(order);
    if (activeTime) {
        return activeTime.customerId;
    }
    if (order.customerIds.length === 1) {
        return order.customerIds[0];
    }

    return null;
}

export function getAlreadyStartedCustomerIds(order: Order) {
    return Array.from(new Set(order.activeTime.map(activeTime => activeTime.customerId).filter(notNullish)));
}

export function constructCustomerTransferredOrder(
    order: Order,
    newCustomerId: Customer["id"],
    /**
     * If null replaces all customerIds with the newCustomerId
     */
    customerIdToReplace: Customer["id"] | null
) {
    const updatedOrder = rfdc()(order);
    updatedOrder.projectId = null;
    if (customerIdToReplace) {
        updatedOrder.customerIds = Array.from(
            new Set(order.customerIds.filter(customerId => customerId !== customerIdToReplace).concat(newCustomerId))
        );
    }
    for (const activeTime of updatedOrder.activeTime) {
        if (!customerIdToReplace || activeTime.customerId === customerIdToReplace) {
            activeTime.customerId = newCustomerId;
        }
    }
    for (const resourceUsage of updatedOrder.resourceUsages) {
        if (!customerIdToReplace || resourceUsage.customerId === customerIdToReplace) {
            resourceUsage.customerId = newCustomerId;
        }
    }
    for (const taskRecord of updatedOrder.taskRecords) {
        if (!customerIdToReplace || taskRecord.customerId === customerIdToReplace) {
            taskRecord.customerId = newCustomerId;
        }
    }
    for (const query of updatedOrder.driverQueriesResourceWithAmount) {
        if (!customerIdToReplace || query.value.customerId === customerIdToReplace) {
            query.value.customerId = newCustomerId;
        }
        if (!customerIdToReplace || query.value.usage?.customerId === customerIdToReplace) {
            query.value.usage = new ResourceUsage({
                ...query.value.usage,
                customerId: newCustomerId,
            });
        }

        for (const historyEntry of query.value.history) {
            if (!customerIdToReplace || historyEntry.customerId === customerIdToReplace) {
                historyEntry.customerId = newCustomerId;
            }
            if (!customerIdToReplace || historyEntry.usage.customerId === customerIdToReplace) {
                historyEntry.usage.customerId = newCustomerId;
            }
        }
    }
    for (const query of updatedOrder.driverQueriesResourceOnly) {
        if (!customerIdToReplace || query.value.customerId === customerIdToReplace) {
            query.value.customerId = newCustomerId;
        }

        for (const historyEntry of query.value.history) {
            if (!customerIdToReplace || historyEntry.customerId === customerIdToReplace) {
                historyEntry.customerId = newCustomerId;
            }
        }
    }
    for (const query of updatedOrder.driverQueriesSingleValue) {
        if (!customerIdToReplace || query.value.customerId === customerIdToReplace) {
            query.value.customerId = newCustomerId;
        }

        for (const historyEntry of query.value.history) {
            if (!customerIdToReplace || historyEntry.customerId === customerIdToReplace) {
                historyEntry.customerId = newCustomerId;
            }
        }
    }
    for (const query of updatedOrder.driverQueriesBeforeAfter) {
        if (!customerIdToReplace || query.value.customerId === customerIdToReplace) {
            query.value.customerId = newCustomerId;
        }
        for (const historyEntry of query.value.history) {
            if (!customerIdToReplace || historyEntry.customerId === customerIdToReplace) {
                historyEntry.customerId = newCustomerId;
            }
        }
    }
    for (const query of updatedOrder.driverQueriesYesNo) {
        if (!customerIdToReplace || query.value.customerId === customerIdToReplace) {
            query.value.customerId = newCustomerId;
        }

        for (const historyEntry of query.value.history) {
            if (!customerIdToReplace || historyEntry.customerId === customerIdToReplace) {
                historyEntry.customerId = newCustomerId;
            }
        }
    }
    for (const protocolItem of updatedOrder.protocol) {
        if (!customerIdToReplace || protocolItem.customerId === customerIdToReplace) {
            protocolItem.customerId = newCustomerId;
        }
    }
    for (const weighing of updatedOrder.weighings) {
        for (const entry of weighing.entries) {
            if (!customerIdToReplace || entry.customerId === customerIdToReplace) {
                entry.customerId = newCustomerId;
            }
        }
    }
    return updatedOrder;
}

/**
 * Always await this function. Does not affect offline support.
 * @param order
 * @param newCustomerId
 * @param customerIdToReplace If null replaces all customerIds with the newCustomerId
 * @param batch
 */
export async function transferTimeTrackingsOfOrderToNewCustomer(
    order: Order,
    newCustomerId: Customer["id"],
    customerIdToReplace: Customer["id"] | null,
    batch: ClientBatchedWrite
) {
    const constraints: QueryConstraint[] = [where("order.orderId", "==", order.id)];
    if (customerIdToReplace) {
        constraints.push(where("order.customerId", "==", customerIdToReplace));
    }
    const timeTrackingDocsToUpdate = (await getDocs(query(Firebase.instance().getAllTimeTrackings(), ...constraints)))
        .docs;

    if (order.decoupleTimeTrackings) {
        timeTrackingDocsToUpdate.push(
            ...(await getDocs(query(Firebase.instance().getDecoupledOrderTimeTrackingsRef(order.id), ...constraints)))
                .docs
        );
    }

    for (const doc of timeTrackingDocsToUpdate) {
        batch.batch().update(doc.ref, {
            "order.customerId": newCustomerId,
            "order.mapStructure": null,
        });
    }
}

/**
 * Always await this function. Does not affect offline support.
 * @param orderId
 * @param newCustomerId
 * @param customerIdToReplace If this is null, all machine counter trackings will be transferred to the new customer.
 * @param batch
 */
export async function transferMachineCounterTrackingsOfOrderToNewCustomer(
    orderId: Order["id"],
    newCustomerId: Customer["id"],
    customerIdToReplace: Customer["id"] | null,
    batch: ClientBatchedWrite
) {
    if (!customerIdToReplace) {
        return;
    }

    const machineCounterTrackingDocs = (
        await getDocs(Firebase.instance().getAllMachineCounterTrackingsByOrder(orderId))
    ).docs;
    for (const doc of machineCounterTrackingDocs) {
        const machineCounterTracking = doc.data();
        if (machineCounterTracking.customerId !== customerIdToReplace) {
            continue;
        }

        const updateObject: FirebaseFirestore.UpdateData<MachineCounterTracking> = {
            customerId: newCustomerId,
        };
        batch.batch().update(doc.ref, updateObject);
    }
}

export async function transferRefuelsOfOrderToNewCustomer(
    orderId: Order["id"],
    newCustomerId: Customer["id"],
    customerIdToReplace: Customer["id"] | null,
    batch: ClientBatchedWrite
) {
    if (!customerIdToReplace) {
        return;
    }

    const refuelDocs = (await getDocs(Firebase.instance().getAllRefuelsForOrder(orderId))).docs;

    for (const doc of refuelDocs) {
        const refuel = doc.data();
        if (!refuel.order) {
            continue;
        }
        if (refuel.order.customerId !== customerIdToReplace) {
            continue;
        }

        const updateData: FirebaseFirestore.UpdateData<Refuel> = {
            "order.customerId": newCustomerId,
        };
        batch.batch().update(doc.ref, updateData);
    }
}

export async function transferTollRecordsOfOrderToNewCustomer(
    orderId: Order["id"],
    newCustomerId: Customer["id"],
    customerIdToReplace: Customer["id"] | null,
    batch: ClientBatchedWrite
) {
    if (!customerIdToReplace) {
        return;
    }

    const tollRecordDocs = (
        await getDocs(Firebase.instance().getOrderTollRecordsForCustomer(orderId, customerIdToReplace))
    ).docs;

    for (const doc of tollRecordDocs) {
        const updateData: FirebaseFirestore.UpdateData<TollRecord> = {
            "order.orderCustomerId": newCustomerId,
        };
        batch.batch().update(doc.ref, updateData);
    }
}

type TransferOrderCustomerDataProps = {
    order: Order;
    newCustomerId: Customer["id"];
    customerIdToReplace: Customer["id"] | null;
};

export async function transferOrderCustomerData(
    props: TransferOrderCustomerDataProps,
    options?: {
        batch: ClientBatchedWrite;
    }
) {
    const updatedOrder = constructCustomerTransferredOrder(props.order, props.newCustomerId, props.customerIdToReplace);
    const batch = options?.batch ?? new ClientBatchedWrite();
    await Firebase.instance().updatePartialOrder(props.order.id, updatedOrder, batch.batch());
    await transferTimeTrackingsOfOrderToNewCustomer(props.order, props.newCustomerId, props.customerIdToReplace, batch);
    await transferMachineCounterTrackingsOfOrderToNewCustomer(
        props.order.id,
        props.newCustomerId,
        props.customerIdToReplace,
        batch
    );
    await transferRefuelsOfOrderToNewCustomer(props.order.id, props.newCustomerId, props.customerIdToReplace, batch);

    await transferTollRecordsOfOrderToNewCustomer(
        props.order.id,
        props.newCustomerId,
        props.customerIdToReplace,
        batch
    );
    if (!options?.batch) {
        batch.commit();
    }
}
