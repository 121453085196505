import { ReactNode, useEffect, useState } from "react";

type ThresholdProps = {
    /**
     * Show children after a fixed amount of time (in ms).
     */
    threshold: number;
    children: ReactNode;
};

export function Threshold(props: ThresholdProps) {
    const { threshold, children } = props;

    const [show, setShow] = useState(false);

    if (!getShouldDelay(threshold) && !show) {
        setShow(true);
    }

    useEffect(() => {
        if (!getShouldDelay(threshold)) {
            return;
        }

        const timeout = setTimeout(() => {
            setShow(true);
        }, threshold);

        return () => {
            clearTimeout(timeout);
        };
    }, [threshold]);

    if (!show) {
        return null;
    }

    return <>{children}</>;
}

function getShouldDelay(treshold: number): boolean {
    return treshold > 0;
}
